import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import clsx from "clsx";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Props = {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (data) => void;
  handleUpload: (data) => void;
  speakerData: any;
  action: string;
  isLoading: boolean;
};

const ModalSpeakers: React.FC<Props> = ({
  show,
  handleClose,
  handleSubmit,
  handleUpload,
  speakerData,
  action,
  isLoading,
}) => {
  const blankData = {
    ordering: 0,
    name: "",
    photo: "",
    bio: "",
    active: 0,
    id: 0,
  };

  //const [file, setFile] = useState(null);
  const [data, setData] = useState<{ [key: string]: any }>(blankData);
  const [hasError, setHasError] = useState(false);
  const [submitData, setSubmitData] = useState(false);

  const quill = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (submitData) {
      handleSubmit(data);
      setSubmitData(false);
    }
  }, [submitData]);

  useEffect(() => {
    setData(speakerData);
  }, [speakerData]);

  const updateData = (fieldsToUpdate: any) => {
    let descData = {};
    if (quill.current) {
      descData = { bio: quill.current.value };
    }
    const updatedData = { ...data, ...fieldsToUpdate, ...descData };
    setData(updatedData);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
  ];

  const checkFields = (): boolean => {
    if (!data.ordering) {
      return false;
    }
    if (!data.name) {
      return false;
    }
    return true;
  };

  const submit = (data) => {
    setHasError(false);
    if (!checkFields()) {
      setHasError(true);
      return;
    }
    //handleSubmit(data);
    if (quill.current) {
      updateData({ bio: quill.current.value }); //console.log(quill.current.value)
      setSubmitData(true);
    } else {
      return;
    }
  };

  const close = () => {
    setSubmitData(false);
    handleClose();
  };

  return (
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-800px h-auto"
      show={show}
      onHide={close}
      //onEntered={loadStepper}
    >
      <div className="container-xxl px-10 py-10">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          <div className="btn btn-icon btn-sm btn-dark" onClick={close}>
            <i className="bi bi-x-lg" />
          </div>
        </div>

        <div className="modal-body">
          <div className="d-flex flex-column flex-xl-row flex-row-fluid">
            <div className="d-flex flex-row-fluid justify-content-center">
              <form className="w-100" noValidate>
                <div className="pb-3 current">
                  {action === "edit" || action === "new" ? (
                    <div className="w-100">
                      <div className="pb-5 pb-lg-10">
                        {data.id > 0 ? (
                          <>
                            <h3 className="fw-bolder text-dark display-6">
                              Edit Speaker ID: {data.id}
                            </h3>
                            <div className="text-muted fw-bold fs-3">
                              Edit the selected speaker.
                            </div>
                          </>
                        ) : (
                          <>
                            <h3 className="fw-bolder text-dark display-6">
                              New Speaker
                            </h3>
                            <div className="text-muted fw-bold fs-3">
                              Create a new speaker profile.
                            </div>
                          </>
                        )}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Status
                        </label>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="active"
                            checked={data.active === 1}
                            onChange={(e) =>
                              updateData({ active: e.target.checked ? 1 : 0 })
                            }
                          />
                          <label className="form-check-label" htmlFor="active">
                            Active?
                          </label>
                        </div>
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label required">
                          Ordering
                        </label>
                        <input
                          type="text"
                          name="ordering"
                          placeholder=""
                          value={data.ordering}
                          onChange={(e) =>
                            updateData({ ordering: e.target.value })
                          }
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                              ["is-invalid"]: !data.ordering && hasError,
                            }
                          )}
                        />
                        {!data.start_at && hasError && (
                          <div className="invalid-feedback">
                            Ordering is required.
                          </div>
                        )}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label required">
                          Speaker Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          placeholder=""
                          value={data.name}
                          onChange={(e) => updateData({ name: e.target.value })}
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                              ["is-invalid"]: !data.name && hasError,
                            }
                          )}
                        />
                        {!data.start_at && hasError && (
                          <div className="invalid-feedback">
                            Name is required.
                          </div>
                        )}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Photo
                        </label>
                        <input
                          type="file"
                          className={clsx(
                            "form-control form-control-lg form-control-solid"
                          )}
                          name="photo"
                          placeholder=""
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              handleUpload(e.target.files[0]);
                            }
                          }}
                        />
                        {data.photo && <img src={data.photo} />}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Speaker Bio / Profile
                        </label>
                        <ReactQuill
                          ref={quill}
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          value={data.bio}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="d-flex justify-content-between pt-10">
                  <div>
                    <button
                      type="button"
                      className={clsx(
                        "btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3",
                        {
                          disabled: isLoading,
                          ["btn-danger"]: action === "delete",
                        }
                      )}
                      onClick={() => submit(data)}
                    >
                      {action === "edit" ? (
                        <span>Save Speaker </span>
                      ) : action === "new" ? (
                        <span>Save New Speaker </span>
                      ) : null}
                      {!isLoading && (
                        <i className={clsx("bi bi-save align-middle ms-2")} />
                      )}
                      {isLoading && (
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      )}
                    </button>
                  </div>
                  <div className="mr-2"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ModalSpeakers };
