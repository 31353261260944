import {
	LOAD_USERS,
	LOAD_USER,
	NEW_USER,
	SAVE_USER,
	ACTIVATE_USER,
	RESET_USER,
	UPDATE_HEADERS,
	RESET_HEADERS,
	START_LOADING,
	STOP_LOADING
} from "../constants/actionTypes";

const defaultState = {
	headers: [{label:'Email',field:'email',sort:true,active:true,dir:'asc'},{label:'First Name',field:'firstname',sort:true,active:false,dir:'asc'},{label:'Last Name',field:'lastname',sort:true,active:false,dir:'asc'},{label:'Confirmed',field:'active',sort:true,active:false,dir:'asc'},{label:'Role',field:'role',sort:false,active:false,dir:'asc'},{label:'',field:'',sort:false,active:false,dir:'asc'}],
	sort: {label:'Email',field:'email',sort:true,active:true,dir:'asc'},
	pagination: {
		total: 0,
		current_page: 1,
		per_page: 20,
		links: []
	},
	users: [],
	currentPage: 1,
	currentLimit: 20,
	currentSearch: '',
	isLoading: false,
	userData: {
		firstname: '',
		lastname: '',
		email: '',
		username: '',
		password: '',
		role: '',
		id: 0
	},
	action: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
		
		case START_LOADING:
			return { 
				...state,
				isLoading: true,
			};
			
		case STOP_LOADING:
			return { 
				...state,
				isLoading: false,
			};
		
		case LOAD_USERS:
			return { 
				...state,
				currentPage: action.payload?.success === true ? action.payload.pagination.current_page : 1,
				currentLimit: action.payload?.success === true ? action.payload.pagination.per_page : 10,
				currentSearch: action.payload?.success === true ? action.payload.search : '',
				users: action.payload?.success === true ? action.payload.users : [],
				pagination: action.payload?.success === true ? action.payload.pagination : defaultState.pagination,
				isLoading: false,
			};
		
		case UPDATE_HEADERS:
			return { 
				...state,
				headers: [...state.headers].map(object => {
					if(object.label === action.payload.label) {
						let dir = 'asc';//console.log(object);
						if(object.active) {//They are clicking it again, toggle sort.
							if(object.dir === 'asc') {dir = 'desc';} else {dir = 'asc';}
						} else {dir = 'asc';}
						return {...object,active: true,dir: dir}
					} else {
						return {...object,active: false}
					}
				}),
				sort: action.payload
			};
			
		case RESET_HEADERS:
			return { 
				...state,
				headers: defaultState.headers,
				sort: defaultState.sort
			};
			
		case LOAD_USER:
			return { 
				...state,
				userData: action.payload?.success === true ? action.payload.user[0] : defaultState.userData,
				isLoading: false,
				action: 'edit'
			};
		
		case NEW_USER:
			return { 
				...state,
				userData: defaultState.userData,
				isLoading: false,
				action: 'new'
			};
			
		case SAVE_USER:
			return { 
				...state,
				userData: defaultState.userData,
				isLoading: false,
				action: ''
			};
			
		case RESET_USER:
		case ACTIVATE_USER:
			return { 
				...state,
				userData: defaultState.userData,
				isLoading: false,
				action: ''
			};
			
    default:
      return state;
  }
};
