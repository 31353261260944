import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../utils'
import clsx from 'clsx'

type Props = {
  className?: string
  chartColor?: string
  chartHeight: string
	chartTitle?: string
	chartDescription?: string
	chartUsersNew: number[]
	chartUsersTotal: number[]
	chartUsersLabels: string[]
	chartTotalNewUsers: number
	chartTotalUsers: number
}

const AdminRegistrations: React.FC<Props> = ({className, chartColor, chartHeight, chartTitle, chartDescription, chartUsersNew, chartUsersTotal, chartUsersLabels, chartTotalNewUsers, chartTotalUsers}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
			return
		}
    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight,chartUsersNew,chartUsersTotal,chartUsersLabels, chartTotalUsers))
    if (chart) {
			chart.render()
		}
    return () => {
      if (chart) {
				chart.destroy()
			}
    }
  }, [chartRef,chartUsersNew])

  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0 bg-${chartColor} py-5`}>
				<div className="card-title">
					<div className="card-label">
						<h3 className="card-title fw-bolder text-white">{chartTitle}</h3>
						<div className="font-size-sm text-white mt-2">{chartDescription}</div>
					</div>
				</div>
      </div>
      <div className='card-body p-0'>
        <div ref={chartRef} className={`mixed-widget-12-chart card-rounded-bottom bg-${chartColor}`}></div>
        <div className='card-rounded bg-white mt-n10 position-relative card-px py-15'>
          <div className='row g-0 mb-7'>
            <div className='col mx-5'>
              <div className='fs-6 text-gray-400'>New Users This Month</div>
              <div className='fs-2 fw-bolder text-gray-800'>{chartTotalNewUsers}</div>
            </div>
            <div className='col mx-5'>
              <div className='fs-6 text-gray-400'>Total Users</div>
              <div className='fs-2 fw-bolder text-gray-800'>{chartTotalUsers}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const chartOptions = (chartHeight: string,chartUsersNew: number[],chartUsersTotal: number[],chartUsersLabels: string[],chartTotal: number): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
	
  return {
    series: [
      {
        name: 'New Users',
        data: chartUsersNew,
      },
      {
        name: 'Total Users',
        data: chartUsersTotal,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {show: false},
    dataLabels: {enabled: false},
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: chartUsersLabels,
      axisBorder: {show: false},
      axisTicks: {show: false},
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: (chartTotal + 1),
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: ['solid', 'solid'],
      opacity: [0.5, 1],
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' users'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['#ffffff', '#ffffff'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
  }
}

export {AdminRegistrations}
