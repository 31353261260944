import {
  LOAD_PDFS,
  LOAD_PDF,
  START_LOADING,
  STOP_LOADING,
} from "../constants/actionTypes";

const defaultState = {
  headers: [
    { label: "Ordering", sort: true, active: true, dir: "asc" },
    { label: "Title", sort: false, active: false, dir: "asc" },
    { label: "External Link", sort: false, active: false, dir: "asc" },
    { label: "Visibility Date", sort: false, active: false, dir: "asc" },
    { label: "Active", sort: false, active: false, dir: "asc" },
    { label: "", sort: false, active: false, dir: "asc" },
  ],
  pagination: {
    total: 0,
    current_page: 1,
    per_page: 20,
    links: [],
  },
  pdfs: [],
  currentPage: 1,
  currentLimit: 20,
  currentSearch: "",
  isLoading: false,
  pdfData: {
    ordering: 0,
    title: "",
    external_link: "",
    visibility_date: new Date(),
    active: 0,
    id: 0,
  },
  action: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case LOAD_PDFS:
      return {
        ...state,
        currentPage:
          action.payload?.success === true
            ? action.payload.pagination.current_page
            : 1,
        currentLimit:
          action.payload?.success === true
            ? action.payload.pagination.per_page
            : 10,
        currentSearch:
          action.payload?.success === true ? action.payload.search : "",
        pdfs: action.payload?.success === true ? action.payload.pdfs : [],
        pagination:
          action.payload?.success === true
            ? action.payload.pagination
            : defaultState.pagination,
        isLoading: false,
      };

    case LOAD_PDF:
      return {
        ...state,
        pdf:
          action.payload?.success === true
            ? action.payload.pdf[0]
            : defaultState.pdfData,
        isLoading: false,
      };

    default:
      return state;
  }
};
