import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import agent from "../agent";
import { APP_LOAD } from "../constants/actionTypes";
import Loading from "./Loading";

import Callback from "../components/Callback";
import Home from "../components/Home";
import Login from "../components/Login";
import Register from "../components/Register";
import Reset from "../components/Reset";

import AdminRoute from "../components/AdminRoute";
import Error404 from "../components/Error404";
import MeetingVideo from "../components/MeetingVideo";
import PdfFile from "../components/PdfFile";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";

import AdminAgendas from "../components/AdminAgendas";
import AdminHome from "../components/AdminHome";
import AdminLogs from "../components/AdminLogs";
import AdminMeeting from "../components/AdminMeeting";
import AdminPdfs from "../components/AdminPdfs";
import AdminSpeakers from "../components/AdminSpeakers";
import AdminUsers from "../components/AdminUsers";
// import AdminGroups from "../components/AdminGroups";

const mapStateToProps = (state) => ({
  ...state.common,
  appLoaded: state.common.appLoaded,
  currentUser: state.common.currentUser,
  redirectTo: state.common.redirectTo,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, token) => dispatch({ type: APP_LOAD, payload, token }),
  //issue_token: (authorization_code) => dispatch({ type: OAUTH_TOKEN, payload: agent.Oauth.access_token(authorization_code) }),
  //onRedirect: () => dispatch({ type: REDIRECT }),
  //onTimeChange: () => dispatch({ type: UPDATE_CURRENT_TIME }),
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 400,
      height: 400,
    };
    //this.handler = this.handler.bind(this);
    //console.log('App Code Props',this.props);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.redirectTo) {
      //store.dispatch(nextProps.redirectTo);
      //this.props.onRedirect();
    }
  }

  componentDidMount() {
    //this.updateWindowDimensions();
    //window.addEventListener('resize', this.updateWindowDimensions);
    const token = window.localStorage.getItem("jwt");
    if (token) {
      agent.setToken(token);
    }
    this.props.onLoad(token ? agent.Auth.current() : {}, token);
    //this.interval = setInterval(() => {
    //this.props.onTimeChange();
    //}, 1000);

    //const eventCode = window.localStorage.getItem('eventCode');
    //if(eventCode) {
    //this.props.onLoad2(eventCode);
    //}
  }

  componentWillUnmount() {
    //clearInterval(this.interval);
  }

  updateWindowDimensions() {
    //this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const splashScreen = document.getElementById("splash-screen");
    //console.log('PROPS',this.props);
    if (this.props.appLoaded) {
      if (splashScreen) {
        splashScreen.style.setProperty("display", "none");
      }
      return (
        <div className="d-flex flex-column flex-column-fluid">
          <Switch>
            <PrivateRoute
              exact={true}
              path="/"
              component={Home}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <PrivateRoute
              exact={false}
              path="/pdf/:id"
              component={PdfFile}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <PrivateRoute
              exact={false}
              path="/video/:id"
              component={MeetingVideo}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <PublicRoute
              exact={true}
              path="/login"
              component={Login}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <PublicRoute
              exact={false}
              path="/login/:code"
              component={Login}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <PublicRoute
              exact={true}
              path="/register"
              component={Register}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <PublicRoute
              exact={true}
              path="/reset"
              component={Reset}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <PublicRoute
              exact={false}
              path="/reset/:code"
              component={Reset}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <PublicRoute
              exact={false}
              path="/callback"
              component={Callback}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <AdminRoute
              exact={true}
              path="/admin/users"
              component={AdminUsers}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <AdminRoute
              exact={true}
              path="/admin/logs"
              component={AdminLogs}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <AdminRoute
              exact={true}
              path="/admin/meeting"
              component={AdminMeeting}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <AdminRoute
              exact={true}
              path="/admin/agendas"
              component={AdminAgendas}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <AdminRoute
              exact={true}
              path="/admin/speakers"
              component={AdminSpeakers}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <AdminRoute
              exact={true}
              path="/admin/pdfs"
              component={AdminPdfs}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <AdminRoute
              exact={true}
              path="/admin/groups"
              component={AdminSpeakers}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <AdminRoute
              exact={false}
              path="/admin"
              component={AdminHome}
              currentUser={this.props.currentUser}
              allProps={this.props}
            />
            <Route path="*">
              <Error404 />
            </Route>
          </Switch>
        </div>
      );
    }
    return (
      <div>
        <Loading />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
