import React from "react";
import { Modal } from "react-bootstrap";

type Props = {
  show: boolean;
  speakers: any[];
  handleClose: () => void;
};

const ModalSpeakersFrontend: React.FC<Props> = ({
  show,
  speakers,
  handleClose,
}) => {
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-800px h-auto"
      show={show}
      onHide={handleClose}
    >
      <div className="container-xxl px-10 py-10">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          <div className="btn btn-icon btn-sm btn-dark" onClick={handleClose}>
            <i className="bi bi-x-lg" />
          </div>
        </div>

        <div className="modal-body">
          <div className="d-flex flex-column flex-xl-row flex-row-fluid">
            <div className="d-flex flex-row-fluid justify-content-center">
              <form className="pb-5 w-100" noValidate>
                <div className="pb-5 current">
                  <div className="w-100">
                    <div className="pb-5 pb-lg-10">
                      <>
                        <h3 className="fw-bolder text-dark display-6">
                          Speaker Profiles
                        </h3>
                      </>
                    </div>

                    <table className="table w-100">
                      <tbody>
                        {speakers?.length > 0
                          ? speakers.map((speaker) => {
                              return (
                                <tr key={speaker.id}>
                                  <td style={{ width: "120px" }}>
                                    {speaker.photo ? (
                                      <div className="symbol w-100 me-5">
                                        <img
                                          src={speaker.photo}
                                          title={speaker.name}
                                        />
                                      </div>
                                    ) : (
                                      <div className="symbol w-100 me-5">
                                        <i className="bi bi-person-fill icon-xxl"></i>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <strong>{speaker.name}</strong>
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        speaker.bio
                                      )}
                                    ></div>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ModalSpeakersFrontend };
