import React from "react";
import { Link } from "react-router-dom";
import ListErrors from "./ListErrors";
import ListSuccess from "./ListSuccess";
import agent from "../agent";
import { connect } from "react-redux";
import queryString from "query-string";
import clsx from "clsx";
import { PasswordMeterComponent } from "../widgets";
//import $ from 'jquery';
import {
  LOADING,
  RESET_TOKEN,
  UPDATE_CODE,
  UPDATE_STEP,
  CLEAR_ERRORS,
  baseUrl,
} from "../constants/actionTypes";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (currentStep, email, password, code) => {
    dispatch({
      type: RESET_TOKEN,
      payload: agent.Auth.reset(currentStep, email, password, code),
    });
  },
  updateCode: (code) => {
    dispatch({ type: UPDATE_CODE, payload: code });
  },
  updateStep: (step) => {
    dispatch({ type: UPDATE_STEP, payload: step });
  },
  clearErrors: () => {
    dispatch({ type: CLEAR_ERRORS });
  },
});

class Reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password2: "",
      code: props.match?.params?.code ? props.match.params.code : "",
      errors: null,
    };
    this.meterRef = React.createRef();
    this.passwordMeter = React.createRef();
  }

  componentWillUnmount() {}

  componentDidMount() {
    const params = queryString.parse(this.props.location); //console.log(params);
    PasswordMeterComponent.bootstrap();
    this.loadMeter();
    this.props.clearErrors();
    if (this.state.code !== "") {
      //console.log(this.state.code);
      //this.setState({step: 2},function() {
      this.props.updateCode(this.state.code);
      if (this.props.step === 2) {
        this.props.onSubmit(
          this.props.step,
          this.state.email,
          this.state.password,
          this.state.code
        );
      } else {
        this.props.updateStep(2);
      }
      //});
    }
  }

  loadMeter = () => {
    this.meterRef.current = document.querySelector(
      "#kt_password_meter_control"
    );
    this.passwordMeter.current = PasswordMeterComponent.getInstance(
      this.meterRef.current
    );
  };

  submitForm = (currentStep) => (ev) => {
    ev.preventDefault();
    let newErrors = [];
    if (currentStep === 1) {
      if (this.state.email === "") {
        newErrors.push("Email is required.");
      }
    } else if (currentStep === 2) {
      if (this.state.code === "") {
        newErrors.push("A valid token is required.");
      }
    } else {
      if (this.state.code === "") {
        newErrors.push(
          "A valid token is required. Try clicking the link in your email again."
        );
      }
      if (this.state.password === "") {
        newErrors.push("Password is required.");
      }
      if (this.state.password2 !== this.state.password) {
        newErrors.push("Passwords do not match.");
      }
      if (this.passwordMeter.current) {
        if (
          this.passwordMeter.current.getScore() < 100 ||
          this.passwordMeter.current.getScore() === null ||
          this.state.password.length < 6
        ) {
          newErrors.push(
            "Passwords is not strong enough, please provide at least one uppercase, one lowercase, one number and one special symbol. Minimum 6 characters."
          );
        }
      }
    }
    this.setState({ errors: newErrors });
    if (newErrors.length === 0) {
      this.setState({ errors: null });
      this.props.onSubmit(
        currentStep,
        this.state.email,
        this.state.password,
        this.props.code
      );
    } //console.log(this.state);
  };

  render() {
    const logoUrl = baseUrl + "images/splash-logo.svg";
    return (
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" className="mb-12">
            <img alt="Revelstoke Capital" src={logoUrl} className="h-65px" />
          </a>
          <div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form
              className="form w-100"
              onSubmit={this.submitForm(this.props.step)}
              noValidate
            >
              {this.props.step === 1 ? (
                <div>
                  <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Forgot Your Password?</h1>
                    <div className="text-gray-400 fw-bold fs-4">
                      Please enter your email to reset your password.
                    </div>
                  </div>
                </div>
              ) : this.props.step === 2 ? (
                <div>
                  <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Verification Email Sent</h1>
                    <div className="text-gray-400 fw-bold fs-4">
                      Please check your email and click the verification link
                      inside the message.
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Set New Password</h1>
                    <div className="text-gray-400 fw-bold fs-4">
                      Please enter a new password.
                    </div>
                  </div>
                </div>
              )}

              {this.props.successMessage ? (
                <ListSuccess errors={this.props.successMessage} />
              ) : null}

              {this.props.errorMessage ? (
                <ListErrors errors={this.props.errorMessage} />
              ) : null}

              {this.state.errors ? (
                <ListErrors errors={this.state.errors} />
              ) : null}

              {this.props.step === 1 ? (
                <div>
                  <div className="fv-row mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Email Address
                    </label>
                    <input
                      placeholder="Email Address"
                      type="email"
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        { "is-invalid": 1 == 2 },
                        { "is-valid": 1 == 2 }
                      )}
                      value={this.state.email}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 mb-5"
                      disabled={this.props.inProgress}
                    >
                      {!this.props.inProgress && (
                        <span className="indicator-label">Submit</span>
                      )}
                      {this.props.inProgress && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>

                    <div className="text-center text-muted text-uppercase fw-bolder mb-5">
                      -
                    </div>

                    <Link to="/">
                      <button
                        type="button"
                        className="btn btn-md bg-gray-300 bg-hover-lighten w-100 mb-5"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              ) : this.props.step === 2 ? (
                <div>
                  <div className="fv-row mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Reset Code
                    </label>
                    <input
                      placeholder="Reset Code"
                      type="text"
                      value={this.state.code}
                      onChange={(event) =>
                        this.setState({ code: event.target.value })
                      }
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        { "is-invalid": 1 == 2 },
                        { "is-valid": 1 == 2 }
                      )}
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 mb-5"
                      disabled={this.props.inProgress}
                    >
                      {!this.props.inProgress && (
                        <span className="indicator-label">Submit Code</span>
                      )}
                      {this.props.inProgress && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>

                    <div className="text-center text-muted text-uppercase fw-bolder mb-5">
                      -
                    </div>

                    <Link to="/">
                      <button
                        type="button"
                        className="btn btn-md bg-gray-300 bg-hover-lighten w-100 mb-5"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              ) : this.props.step === 0 ? (
                <div>
                  <div className="text-center">
                    <div className="text-center text-muted text-uppercase fw-bolder mb-5">
                      -
                    </div>
                    <Link to="/">
                      <button
                        type="button"
                        className="btn btn-md bg-gray-300 bg-hover-lighten w-100 mb-5"
                      >
                        Return to Login
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className="fv-row mb-10"
                    data-kt-password-meter="true"
                    ref={this.meterRef}
                    id="kt_password_meter_control"
                  >
                    <div className="mb-1">
                      <label className="form-label fw-bolder text-dark fs-6">
                        New Password
                      </label>
                      <div className="position-relative mb-3">
                        <input
                          type="password"
                          autoComplete="off"
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            { "is-invalid": 1 == 2 },
                            { "is-valid": 1 == 2 }
                          )}
                          value={this.state.password}
                          onChange={(event) =>
                            this.setState({ password: event.target.value })
                          }
                        />
                      </div>
                      <div
                        className="d-flex align-items-center mb-3"
                        data-kt-password-meter-control="highlight"
                      >
                        <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px"></div>
                      </div>
                    </div>
                    <div className="text-muted fs-7">
                      At least one uppercase, one lowercase, one number and one
                      special symbol. Minimum 6 characters.
                    </div>
                  </div>

                  <div className="fv-row mb-10">
                    <div className="d-flex justify-content-between mt-n5">
                      <div className="d-flex flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">
                          Confirm New Password
                        </label>
                      </div>
                    </div>
                    <input
                      type="password"
                      autoComplete="off"
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        { "is-invalid": 1 == 2 },
                        { "is-valid": 1 == 2 }
                      )}
                      value={this.state.password2}
                      onChange={(event) =>
                        this.setState({ password2: event.target.value })
                      }
                    />
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 mb-5"
                      disabled={this.props.inProgress}
                    >
                      {!this.props.inProgress && (
                        <span className="indicator-label">
                          Update New Password
                        </span>
                      )}
                      {this.props.inProgress && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>

                    <div className="text-center text-muted text-uppercase fw-bolder mb-5">
                      -
                    </div>

                    <Link to="/">
                      <button
                        type="button"
                        className="btn btn-md bg-gray-300 bg-hover-lighten w-100 mb-5"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
