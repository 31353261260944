import React from 'react';

class ListSuccess extends React.Component {
  render() {
    const errors = this.props.errors;
    if (errors) {
			if(errors.constructor === Array) {
	      return (
					<div className='mb-lg-15 alert alert-success'>
	          {
	            Object.keys(errors).map(key => {
	              return (
	                <div className='alert-text font-weight-bold' key={key}>
	                  {errors[key]}
	                </div>
	              );
	            })
	          }
	        </div>
	      );
			} else {
				return (
					<div className='mb-lg-15 alert alert-success'>
	          <div className='alert-text font-weight-bold'>
	            {errors}
	          </div>
	        </div>
	      );
			}
    } else {
      return null;
    }
  }
}

export default ListSuccess;
