import React from 'react';
//import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';

//const mapDispatchToProps = dispatch => ({
  //onSetPage: (page, payload) => dispatch({ type: SET_PAGE, page, payload })
//});

const PublicRoute = props => {  
  //console.log('PublicRoute',props);
  if(props.currentUser) {
    return (
      <PrivateRoute exact={props.exact} path={props.path} component={props.component} currentUser={props.currentUser} allProps={props} params={props.params} />
    );
  } else {    
    return (
      <Route exact={props.exact} path={props.path} component={props.component} params={props.params} />
    );
  }
};

export default PublicRoute;
