import React from "react";
import ListErrors from "./ListErrors";
import ListSuccess from "./ListSuccess";
import agent from "../agent";
import { connect } from "react-redux";
import {
  LOGIN,
  LOAD_OAUTH,
  RESET_CODE,
  baseUrl,
  //EVENT_BY_CODE,
  //API_FILES_ROOT
} from "../constants/actionTypes";
//import { useLocation } from "react-router-dom";
import { Link, Redirect } from "react-router-dom";
import clsx from "clsx";
//window.navigator.userAgent
const mapStateToProps = (state) => ({ ...state.auth });

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (username, password, otpcode) =>
    dispatch({
      type: LOGIN,
      payload: agent.Auth.login(username, password, otpcode),
    }),
  resetCode: () => dispatch({ type: RESET_CODE }),
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      otpcode: "",
      errors: null,
    };
    this.handleUsernameChange = (event) => {
      this.setState({ username: event.target.value });
    };
    this.handlePasswordChange = (event) => {
      this.setState({ password: event.target.value });
    };
    this.handleCodeChange = (event) => {
      this.setState({ otpcode: event.target.value });
    };
  }

  componentDidMount() {
    //let timeNow = Date.parse(new Date());
    //console.log(window.navigator.userAgent);
  }

  componentWillUnmount() {}

  submitForm = (username, password, otpcode) => (ev) => {
    ev.preventDefault();
    if (username === "" || password === "") {
      this.setState({ errors: ["Email or password is blank."] });
    } else {
      this.setState({ errors: null });
      this.props.onSubmit(username, password, otpcode);
    }
  };

  render() {
    const logoUrl = baseUrl + "images/splash-logo.svg";
    return (
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed home">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" className="mb-12">
            <img alt="Revelstoke Capital" src={logoUrl} className="h-65px" />
          </a>
          {this.props.otpcode ? (
            <div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
              <form
                className="form w-100"
                onSubmit={this.submitForm(
                  this.state.username,
                  this.state.password,
                  this.state.otpcode
                )}
                autoComplete="off"
                noValidate
              >
                <div className="text-center mb-10">
                  <h1 className="text-dark mb-3">
                    Welcome to the live event meeting portal
                  </h1>
                  <div className="text-gray-400 fw-bold fs-4">
                    Two Factor Authentication Code Required
                  </div>
                </div>

                {this.props.successMessage ? (
                  <ListSuccess errors={this.props.successMessage} />
                ) : null}

                {this.props.errorMessage ? (
                  <ListErrors errors={this.props.errorMessage} />
                ) : null}

                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark">
                    Enter 6 Digit Code
                  </label>
                  <input
                    placeholder="Enter Code"
                    value={this.state.otpcode}
                    onChange={this.handleCodeChange}
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      { "is-invalid": 1 == 2 },
                      { "is-valid": 1 == 2 }
                    )}
                    type="text"
                    name="code"
                    autoComplete="off"
                  />
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                    disabled={this.props.inProgress}
                  >
                    {!this.props.inProgress && (
                      <span className="indicator-label">Verify Code</span>
                    )}
                    {this.props.inProgress && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>

                  <div className="text-center text-muted text-uppercase fw-bolder mb-4">
                    -
                  </div>

                  <button
                    type="button"
                    className="btn btn-lg btn-light w-100 mb-4"
                    onClick={() => this.props.resetCode()}
                  >
                    <span className="indicator-label">Cancel</span>
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
              <form
                className="form w-100"
                onSubmit={this.submitForm(
                  this.state.username,
                  this.state.password
                )}
                autoComplete="off"
                noValidate
              >
                <div className="text-center mb-10">
                  <h1 className="text-dark mb-3">
                    Welcome to the live event meeting portal
                  </h1>
                  <div className="text-gray-400 fw-bold fs-4">
                    Please login to access the event
                  </div>
                </div>

                {this.props.successMessage ? (
                  <ListSuccess errors={this.props.successMessage} />
                ) : null}

                {this.props.errorMessage ? (
                  <ListErrors errors={this.props.errorMessage} />
                ) : null}

                {this.state.errors ? (
                  <ListErrors errors={this.state.errors} />
                ) : null}

                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark">
                    Email Address
                  </label>
                  <input
                    placeholder="Email Address"
                    value={this.state.username}
                    onChange={this.handleUsernameChange}
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      { "is-invalid": 1 == 2 },
                      { "is-valid": 1 == 2 }
                    )}
                    type="email"
                    name="email"
                    autoComplete="off"
                  />
                </div>

                <div className="fv-row mb-10">
                  <div className="d-flex justify-content-between mt-n5">
                    <div className="d-flex flex-stack mb-2">
                      <label className="form-label fw-bolder text-dark fs-6 mb-0">
                        Password
                      </label>
                    </div>
                  </div>
                  <input
                    type="password"
                    autoComplete="off"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      { "is-invalid": 1 == 2 },
                      { "is-valid": 1 == 2 }
                    )}
                    placeholder="Password"
                  />
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary w-100 mb-4"
                    disabled={this.props.inProgress}
                  >
                    {!this.props.inProgress && (
                      <span className="indicator-label">Log In</span>
                    )}
                    {this.props.inProgress && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>

                  <div className="text-center text-muted text-uppercase fw-bolder mb-4">
                    -
                  </div>

                  <Link
                    to="/register"
                    className="btn btn-lg btn-secondary w-100 mb-4"
                  >
                    Register
                  </Link>

                  <div className="text-center text-muted text-uppercase fw-bolder mb-4">
                    -
                  </div>

                  <Link
                    to="/reset"
                    className="link-primary fs-6 fw-bolder"
                    style={{ marginLeft: "0px" }}
                  >
                    Reset Password
                  </Link>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
