import React from "react";
//import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
//import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to="/admin"
        title="Dashboard"
        fontIcon="bi-card-text"
        bsTitle="Dashboard"
        className="py-2"
      />

      <AsideMenuItem
        to="/admin/users"
        title="Users"
        fontIcon="bi-people"
        bsTitle="Users"
        className="py-2"
      />

      <AsideMenuItem
        to="/admin/logs"
        title="Logs"
        fontIcon="bi-activity"
        bsTitle="Logs"
        className="py-2"
      />

      <AsideMenuItem
        to="/admin/meeting"
        title="Meeting"
        fontIcon="bi-calendar"
        bsTitle="Meeting"
        className="py-2"
      />

      <AsideMenuItem
        to="/admin/agendas"
        title="Agendas"
        fontIcon="bi-collection-fill"
        bsTitle="Agendas"
        className="py-2"
      />

      <AsideMenuItem
        to="/admin/speakers"
        title="Speakers"
        fontIcon="bi-file-person"
        bsTitle="Speakers"
        className="py-2"
      />

      <AsideMenuItem
        to="/admin/pdfs"
        title="PDFs"
        fontIcon="bi-file-pdf-fill"
        bsTitle="PDFs"
        className="py-2"
      />

      <AsideMenuItem
        to="/admin/groups"
        title="Group Roles"
        fontIcon="bi-diagram-3-fill"
        bsTitle="Group Roles"
        className="py-2"
      />
    </>
  );
}
