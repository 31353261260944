/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useLayout } from "../core";

const Footer: React.FC = () => {
  const { classes } = useLayout();
  let thisYear = new Date().getFullYear();
  let app_name = process.env.REACT_APP_APP_TITLE
    ? process.env.REACT_APP_APP_TITLE
    : "";
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      <div
        className={`${classes.footerContainer} container-xxl d-flex flex-column flex-md-row flex-stack`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="font-size-xs text-gray-400 fw-bold me-1">
            &copy; {thisYear} Revelstoke Capital Management, LLC. All rights
            reserved.
          </span>
        </div>

        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1 font-size-xs d-none">
          <li className="menu-item">
            <a href="#" className="menu-link px-2">
              Privacy Policy
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link px-2">
              Terms of Use
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link px-2">
              Support
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { Footer };
