import { saveAs } from "file-saver";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  meeting: state.meeting.meeting,
});

class ICSDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  generateICS = () => {
    const { start_at, end_at, title } = this.props.meeting;
    const startDate = moment.utc(start_at).local().format("YYYYMMDDTHHmmss");
    const endDate = moment.utc(end_at).local().format("YYYYMMDDTHHmmss");

    const icsMSG = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${title}
DTSTART:${startDate}
DTEND:${endDate}
STATUS:CONFIRMED
SEQUENCE:3
DESCRIPTION:\\nhttps://live.revelstokecapital.com\\n\\nNeed Help?\\nPlease use the “Support” button in the portal or reach out to:\\n\\nJulie Allen (Operations)\\njallen@revelstokecapital.com\\nText or call: (303) 358-2233\\n\\nIT Support Team\\nrcplivesupport@kisecurity.com\\nText or call: (720) 661-1644
LOCATION:Revelstoke Live Meeting Portal - Link Below
BEGIN:VALARM
TRIGGER:-PT10M
ACTION:DISPLAY
END:VALARM
END:VEVENT
END:VCALENDAR
`;

    const blob = new Blob([icsMSG], { type: "text/calendar" });
    saveAs(blob, "revelstoke_annual_meeting.ics");
  };

  render() {
    return (
      <a className="btn btn-primary" onClick={this.generateICS}>
        Add Meeting To Your Calendar
      </a>
    );
  }
}

export default connect(mapStateToProps)(ICSDownload);
