import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = props => {
	//console.log('PrivateRoute: ',props);
	if(props.currentUser) {
		if(props.currentUser.closed) { //If the application is closed
			if(props.path === '/logout') { //Avoid infinite redirect loop going to logout page
				return (<Route exact={props.exact} path='/logout' component={props.component} params={props.params} />);
			}
			return (<Redirect to={{ pathname: "/logout", state: { from: props.path, errors: ['The application is temporarily down for maintenance.'] } }} />);
		}

		if(props.path.includes('/login') || props.path.includes('/register') || props.path.includes('/callback') || props.path.includes('/validate')) { //If we came from the login or register page, application is open and user logged in.
			return (<Redirect to={{ pathname: "/", state: { from: props.path } }} />);
		}
		return (<Route exact={props.exact} path={props.path} component={props.component} params={props.params} />);

	} else {
		return (
			<Redirect
				to={{
					pathname: "/login",
					state: { from: props.path }
				}}
			/>
		);
	}
};

export default PrivateRoute;
