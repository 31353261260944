export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const SETTINGS_SAVED = "SETTINGS_SAVED";
export const SETTINGS_PAGE_UNLOADED = "SETTINGS_PAGE_UNLOADED";
export const SET_PAGE = "SET_PAGE";
export const LOGIN = "LOGIN";
export const EVENT_BY_CODE = "EVENT_BY_CODE";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const LOG_ACTION = "LOG_ACTION";
export const UPDATE_CURRENT_TIME = "UPDATE_CURRENT_TIME";
export const RESET_TOKEN = "RESET_TOKEN";
export const UPDATE_CODE = "UPDATE_CODE";
export const UPDATE_STEP = "UPDATE_STEP";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const RESET_CODE = "RESET_CODE";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USER = "LOAD_USER";
export const NEW_USER = "NEW_USER";
export const SAVE_USER = "NEW_USER";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const RESET_USER = "RESET_USER";

export const UPDATE_HEADERS = "UPDATE_HEADERS";
export const RESET_HEADERS = "RESET_HEADERS";

export const LOAD_REGISTRATIONS_WIDGET = "LOAD_REGISTRATIONS_WIDGET";

export const LOAD_LOGS = "LOAD_LOGS";
export const PURGE_LOGS = "PURGE_LOGS";

export const LOAD_MEETING = "LOAD_MEETING";
export const UPDATE_MEETING = "UPDATE_MEETING";

export const LOAD_AGENDAS = "LOAD_AGENDAS";

export const RECORD_SCORE = "RECORD_SCORE";
export const RECORD_CLICK = "RECORD_CLICK";
export const LOAD_OAUTH = "LOAD_OAUTH";
export const OAUTH_TOKEN = "OAUTH_TOKEN";

export const LOAD_SPEAKERS = "LOAD_SPEAKERS";

export const LOAD_PDFS = "LOAD_PDFS";
export const LOAD_PDF = "LOAD_PDF";

export const LOAD_GROUPS = "LOAD_GROUPS";

let base_url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL_PRODUCTION
    : process.env.REACT_APP_BASE_URL;
export const baseUrl = base_url;

let apiroot =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_ROOT_PRODUCTION
    : process.env.REACT_APP_API_ROOT;
export const API_ROOT = apiroot;

let files_url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_FILE_URL_PRODUCTION
    : process.env.REACT_APP_FILE_URL;
export const API_FILES_ROOT = files_url;
