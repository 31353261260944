import {
  LOGIN,
  REGISTER,
  ASYNC_START,
	RESET_TOKEN,
	CLEAR_ERRORS,
	UPDATE_CODE,
	UPDATE_STEP,
	RESET_CODE
} from '../constants/actionTypes';

const defaultState = {
	errorMessage: '',
	successMessage: '',
  token: null,
	inProgress: false,
	step: 1,
	code: '',
	otpcode: false
};

export default (state = defaultState, action) => {
  //console.log('--------LOGIN reducer',action);
  switch (action.type) {
    case LOGIN:
    case REGISTER:
			return {
				...state,
				inProgress: false,
				otpcode: action.payload?.otpcode ? action.payload.otpcode : false,
				errorMessage: action.payload?.success === false ? action.payload.message : '',
				successMessage: action.payload?.success === true ? action.payload.message : '',
			};
    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === REGISTER || action.subtype === RESET_TOKEN) {
        return { ...state, inProgress: true };
      }
      break;
		case RESET_TOKEN:
      return {
        ...state,
				inProgress: false,
        errorMessage: action.payload?.success === false ? action.payload.message : '',
				successMessage: action.payload?.success === true ? action.payload.message : '',
				step: action.payload.step,
      };
		case UPDATE_CODE:
			return {
				...state,
				code: action.payload
			};
		case UPDATE_STEP:
			return {
				...state,
				step: action.payload
			};
		case CLEAR_ERRORS :
			return {
				...state,
				errorMessage: '',
				//successMessage: '',
				step: 1,
				code: ''
			};
		case RESET_CODE :
			return {
				...state,
				otpcode: false,
				errorMessage: '',
				successMessage: ''
			};
    default:
      return state;
  }

  return state;
};
