import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import clsx from "clsx";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Props = {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (data) => void;
  pdfData: any;
  action: string;
  isLoading: boolean;
};

const ModalPdfs: React.FC<Props> = ({
  show,
  handleClose,
  handleSubmit,
  pdfData,
  action,
  isLoading,
}) => {
  const blankData = {
    ordering: 0,
    title: "",
    external_link: "",
    visibility_date: new Date(),
    active: 0,
    id: 0,
  };

  const [data, setData] = useState<{ [key: string]: any }>(blankData);
  const [hasError, setHasError] = useState(false);
  const [submitData, setSubmitData] = useState(false);

  useEffect(() => {
    if (submitData) {
      handleSubmit(data);
      setSubmitData(false);
    }
  }, [submitData]);

  useEffect(() => {
    setData(pdfData);
  }, [pdfData]);

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkFields = (): boolean => {
    if (!data.ordering) {
      return false;
    }
    if (!data.title) {
      return false;
    }
    if (!data.external_link) {
      return false;
    }
    if (!data.visibility_date) {
      return false;
    }
    return true;
  };

  const submit = (data) => {
    setHasError(false);
    if (!checkFields()) {
      setHasError(true);
      return;
    }
    //handleSubmit(data);
    setSubmitData(true);
  };

  const close = () => {
    setSubmitData(false);
    handleClose();
  };

  const visibility_date = moment.utc(data.visibility_date).format(); //console.log(start_at, "- now in UTC");
  const local_visibility_date = moment.utc(visibility_date).local().toDate(); //console.log(local_start_at, "- UTC now to local");

  const tzName = new Date().toLocaleDateString(navigator.language, {
    timeZoneName: "short",
  });
  const tzNameSplit = tzName.split(", ")[1];

  return (
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-800px h-auto"
      show={show}
      onHide={close}
      //onEntered={loadStepper}
    >
      <div className="container-xxl px-10 py-10">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          <div className="btn btn-icon btn-sm btn-dark" onClick={close}>
            <i className="bi bi-x-lg" />
          </div>
        </div>

        <div className="modal-body">
          <div className="d-flex flex-column flex-xl-row flex-row-fluid">
            <div className="d-flex flex-row-fluid justify-content-center">
              <form className="w-100" noValidate>
                <div className="pb-3 current">
                  {action === "edit" || action === "new" ? (
                    <div className="w-100">
                      <div className="pb-5 pb-lg-10">
                        {data.id > 0 ? (
                          <>
                            <h3 className="fw-bolder text-dark display-6">
                              Edit PDF ID: {data.id}
                            </h3>
                            <div className="text-muted fw-bold fs-3">
                              Edit the selected pdf.
                            </div>
                          </>
                        ) : (
                          <>
                            <h3 className="fw-bolder text-dark display-6">
                              New PDF
                            </h3>
                            <div className="text-muted fw-bold fs-3">
                              Create a new pdf.
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row mb-8">
                        <div className="col-6">
                          <label className="fs-6 fw-bolder text-dark form-label required">
                            Available At (in {tzNameSplit})
                          </label>
                          <DateTimePicker
                            name="visibility_date"
                            onChange={(e) => updateData({ visibility_date: e })}
                            value={local_visibility_date}
                            calendarClassName="card shadow-sm"
                            calendarIcon={
                              <i className="bi bi-calendar text-dark"></i>
                            }
                            clearIcon={
                              <i className="bi bi-x-square text-dark"></i>
                            }
                            className={clsx(
                              "form-control form-control-lg form-control-solid",
                              {
                                ["is-invalid"]:
                                  !data.visibility_date && hasError,
                              }
                            )}
                            clockClassName=""
                          />
                          {!data.visibility_date && hasError && (
                            <div className="invalid-feedback">
                              Available at time is required.
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Status
                        </label>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="active"
                            checked={data.active === 1}
                            onChange={(e) =>
                              updateData({ active: e.target.checked ? 1 : 0 })
                            }
                          />
                          <label className="form-check-label" htmlFor="active">
                            Active?
                          </label>
                        </div>
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label required">
                          Ordering
                        </label>
                        <input
                          type="text"
                          name="ordering"
                          placeholder=""
                          value={data.ordering}
                          onChange={(e) =>
                            updateData({ ordering: e.target.value })
                          }
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                              ["is-invalid"]: !data.ordering && hasError,
                            }
                          )}
                        />
                        {!data.start_at && hasError && (
                          <div className="invalid-feedback">
                            Ordering is required.
                          </div>
                        )}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label required">
                          Title
                        </label>
                        <input
                          type="text"
                          name="title"
                          placeholder=""
                          value={data.title}
                          onChange={(e) =>
                            updateData({ title: e.target.value })
                          }
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                              ["is-invalid"]: !data.title && hasError,
                            }
                          )}
                        />
                        {!data.start_at && hasError && (
                          <div className="invalid-feedback">
                            Title is required.
                          </div>
                        )}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          External Link URL
                        </label>
                        <input
                          type="text"
                          className={clsx(
                            "form-control form-control-lg form-control-solid"
                          )}
                          name="external_link"
                          placeholder=""
                          value={data.external_link}
                          onChange={(e) =>
                            updateData({ external_link: e.target.value })
                          }
                        />
                        {!data.external_link && hasError && (
                          <div className="invalid-feedback">
                            External Link URL is required.
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="d-flex justify-content-between pt-10">
                  <div>
                    <button
                      type="button"
                      className={clsx(
                        "btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3",
                        {
                          disabled: isLoading,
                          ["btn-danger"]: action === "delete",
                        }
                      )}
                      onClick={() => submit(data)}
                    >
                      {action === "edit" ? (
                        <span>Save Pdf </span>
                      ) : action === "new" ? (
                        <span>Save New Pdf </span>
                      ) : null}
                      {!isLoading && (
                        <i className={clsx("bi bi-save align-middle ms-2")} />
                      )}
                      {isLoading && (
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      )}
                    </button>
                  </div>
                  <div className="mr-2"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ModalPdfs };
