import {
	LOAD_LOGS,
	PURGE_LOGS,
	START_LOADING
} from "../constants/actionTypes";

const defaultState = {
	headers: [{label:'Date',sort:true,active:true,dir:'desc'},{label:'User',sort:false,active:false,dir:'asc'},{label:'Status',sort:false,active:false,dir:'asc'},{label:'Action',sort:false,active:false,dir:'asc'},{label:'Body',sort:false,active:false,dir:'asc'}],
	pagination: {
		total: 0,
		current_page: 1,
		per_page: 20,
		links: []
	},
	logs: [],
	currentPage: 1,
	currentLimit: 20,
	currentSearch: '',
	isLoading: false,
	thisData: {
		id: 0
	},
	action: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
		
		case START_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
		
		case LOAD_LOGS:
			return { 
				...state,
				currentPage: action.payload?.success === true ? action.payload.pagination.current_page : 1,
				currentLimit: action.payload?.success === true ? action.payload.pagination.per_page : 10,
				currentSearch: action.payload?.success === true ? action.payload.search : '',
				logs: action.payload?.success === true ? action.payload.logs : [],
				pagination: action.payload?.success === true ? action.payload.pagination : defaultState.pagination,
				isLoading: false,
			};
		
		case PURGE_LOGS:
			return { 
				...state,
				isLoading: false,
				action: ''
			};
			
    default:
      return state;
  }
};
