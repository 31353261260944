import React from 'react'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../partials'
import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-lg-3',
  btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-40px',
  btnIconClass = 'svg-icon-1'

type Props = {
	currentUser: any
}

const Topbar: React.FC<Props> = ({currentUser}) => {
  const {config} = useLayout()
	
	const userInitialsF = (currentUser?.firstname)?currentUser?.firstname.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase():'T';
	const userInitialsL = (currentUser?.lastname)?currentUser?.lastname.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase():'U';
	
	const isAdmin = (currentUser?.role)?(currentUser?.role==='admin'):false;
	
  return (
    <div className='d-flex align-items-stretch justify-self-end flex-shrink-0'>
		
      {/* Activities
      <div className={clsx('d-flex align-items-center', itemClass)}>
        <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', btnClass)}
          id='kt_activities_toggle'
        >
          <KTSVG path='/images/utility/gen032.svg' className={btnIconClass} />
        </div>
      </div>
			*/}

      {/* NOTIFICATIONS
      <div className={clsx('d-flex align-items-center', itemClass)}>
        <div
          className={clsx('btn btn-icon btn-active-light-primary position-relative ', btnClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTSVG path='/images/utility/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>
			*/}

      {/* CHAT
      <div className={clsx('d-flex align-items-center', itemClass)}>
        <div className={clsx('btn btn-icon btn-active-light-primary btn-custom position-relative',btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/images/utility/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div>
			*/}
			
			{isAdmin === true ?
      <div className={clsx('d-flex align-items-center', itemClass)}>
        <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', btnClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <i className="bi bi-grid-fill icon-xl" />
        </div>
        <QuickLinks />
      </div>
			: null }

      <div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
        <a
          className={clsx('btn btn-primary btn-icon cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
					<i className="bi bi-person-fill icon-xl" />
        </a>
        <HeaderUserMenu currentUser={currentUser} />
      </div>

      {/* begin::Aside Toggler
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/images/utility/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
			*/}
    </div>
  )
}

export {Topbar}
