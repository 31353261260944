import React, { useState, useEffect, useRef }  from 'react'
import clsx from 'clsx'

type Props = {
	countdownDate: number
	handleCompleteTimer: () => void
}

const Countdownclock: React.FC<Props> = ({countdownDate, handleCompleteTimer}) => {
	
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [min, setMin] = useState(0);
	const [sec, setSec] = useState(0);
	const [completion, setCompletion] = useState(true);
	
	const intervalCalc = setInterval(() => {
		const date = calculateCountdown(countdownDate);
		date ? changeDate(date) : stop();
	}, 1000);
	
	useEffect(() => {
		return () => {
			
		}
	}, [])
	
	const changeDate = (value:any) => {
		setDays(value.days);
		setHours(value.hours);
		setMin(value.min);
		setSec(value.sec);
	}
	
  const calculateCountdown = (endDate:number) => {

    let timeNow = new Date();
    let timeEnd = new Date(endDate);
    let diff = (timeEnd.getTime() - timeNow.getTime()) / 1000;
    // clear countdown when date is reached
    if (diff <= 0) {
      return false;
    }
    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      completion:false,
    };
    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = Math.floor(diff);

    return timeLeft;
  }

	const stop = () => {
		clearInterval(intervalCalc);
		handleCompleteTimer();
		setDays(0);
		setHours(0);
		setMin(0);
		setSec(0);
		setCompletion(true);
	}

  const addLeadingZeros = (value:any) => {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }
	
  return (
    <div className="Countdown d-flex flex-row align-items-center flex-center my-8">
      {days > 0 ?
      <span className="Countdown-col d-flex flex-column btn btn-white text-dark shadow-sm">
        <strong className="fw-boldest fs-1">{addLeadingZeros(days)}</strong>
        <span className="fs-8">Days</span>
      </span>
      : null }
      {days > 0 ?
      <span className="font-weight-boldest mx-2 mb-1">:</span>
      : null }
      {hours > 0 ?
      <span className="Countdown-col d-flex flex-column btn btn-white text-dark shadow-sm">
        <strong className="fw-boldest fs-1">{addLeadingZeros(hours)}</strong>
        <span className="fs-8">Hours</span>
      </span>
      : null }
      {hours > 0 ?
      <span className="font-weight-boldest mx-2 mb-1">:</span>
      : null }
      <span className="Countdown-col d-flex flex-column btn btn-white text-dark shadow-sm">
        <strong className="fw-boldest fs-1">{addLeadingZeros(min)}</strong>
        <span className="fs-8">Min</span>
      </span>
      <span className="font-weight-boldest mx-2 mb-1">:</span>
      <span className="Countdown-col d-flex flex-column btn btn-white text-dark shadow-sm">
        {sec === 0 ?
          <strong className="fw-boldest fs-1">00</strong>
        :
          <strong className="fw-boldest fs-1">{addLeadingZeros(sec)}</strong>
        }
        <span className="fs-8">Sec</span>
      </span>
    </div>
  );
}

export {Countdownclock};
