import agent from "./agent";
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  REGISTER,
  APP_LOAD,
  RESET_TOKEN,
} from "./constants/actionTypes";

const promiseMiddleware = (store) => (next) => (action) => {
  //console.log('--------promiseMiddleware',action);
  //console.log('--------promiseMiddleware',isPromise(action.payload));
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      (res) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        //console.log('RESULT', res);
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      (error) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        //console.log('ERROR', error);
        action.error = true;
        //action.payload = error.response.body;
        action.payload = error;
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      }
    );

    return;
  }
  next(action);
};

const localStorageMiddleware = (store) => (next) => (action) => {
  //console.log('--------localStorageMiddleware',action);
  //console.log('--------localStorageMiddleware',action);
  //if (isPromise(action.payload)) {
  if (
    action.type === REGISTER ||
    action.type === LOGIN ||
    action.type === APP_LOAD ||
    action.type === RESET_TOKEN
  ) {
    if (action.payload?.token) {
      window.localStorage.setItem("jwt", action.payload.token);
      //window.localStorage.setItem('introWatched', 0);
      agent.setToken(action.payload.token);
    }
  }
  if (action.type === LOGOUT) {
    window.localStorage.setItem("jwt", "");
    //window.localStorage.setItem('introWatched', 0);
    agent.setToken(null);
  }
  // if (action.type === INTRO_WATCHED) {
  //   window.localStorage.setItem('introWatched', 1);
  // }
  // if (action.type === EVENT_BY_CODE) {
  //   window.localStorage.setItem('eventAssets', {});
  // }
  //return;
  //}
  next(action);
};

function isPromise(v) {
  return v && typeof v.then === "function";
}

export { promiseMiddleware, localStorageMiddleware };
