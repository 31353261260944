import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { PasswordMeterComponent } from "../";

type Props = {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (data) => void;
  handleActivate: (userId) => void;
  userData: any;
  action: string;
  isLoading: boolean;
};

const ModalUsers: React.FC<Props> = ({
  show,
  handleClose,
  handleSubmit,
  handleActivate,
  userData,
  action,
  isLoading,
}) => {
  const blankUser = {
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    role: "",
    id: 0,
  };

  const meterRef = useRef<HTMLDivElement | null>(null);
  const passwordMeter = useRef<PasswordMeterComponent | null>(null);
  const [data, setData] = useState<{ [key: string]: any }>(blankUser);

  const [hasError, setHasError] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  useEffect(() => {
    setData(userData);
  }, [userData, show]);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    loadMeter();
  }, [show, userData.id]);

  const loadMeter = () => {
    meterRef.current = document.querySelector("#kt_password_meter_control");
    passwordMeter.current = PasswordMeterComponent.getInstance(
      meterRef.current as HTMLDivElement
    );
  };

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkFields = (): boolean => {
    if (!data.firstname) {
      return false;
    }
    if (!data.lastname) {
      return false;
    }
    if (!data.email) {
      return false;
    }
    if (!data.role) {
      return false;
    }
    return true;
  };

  const checkPassword = (): boolean => {
    if (data.password !== "" && passwordMeter.current) {
      if (passwordMeter.current.getScore() < 100) {
        return false;
      }
    }
    return true;
  };

  const submit = (data) => {
    setHasError(false);
    setPasswordInvalid(false);
    if (!checkFields()) {
      setHasError(true);
      return;
    }
    if (!checkPassword()) {
      setPasswordInvalid(true);
      return;
    }
    handleSubmit(data);
  };

  const activate = (data) => {
    if (data.id) {
      handleActivate(data.id);
    }
  };

  return (
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-800px h-auto"
      show={show}
      onHide={handleClose}
      //onEntered={loadStepper}
    >
      <div className="container-xxl px-10 py-10">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          <div className="btn btn-icon btn-sm btn-dark" onClick={handleClose}>
            <i className="bi bi-x-lg" />
          </div>
        </div>

        <div className="modal-body">
          <div className="d-flex flex-column flex-xl-row flex-row-fluid">
            <div className="d-flex flex-row-fluid justify-content-center">
              <form className="pb-5 w-100" noValidate>
                <div className="pb-5 current">
                  {action === "delete" ? (
                    <div className="w-100">
                      <div className="pb-5 pb-lg-10">
                        <h3 className="fw-bolder text-dark display-6">
                          Delete User ID: {data.id}
                        </h3>
                        <div className="text-muted fw-bold fs-3">
                          Are you sure you want to delete this user?
                        </div>
                      </div>
                    </div>
                  ) : action === "edit" || action === "new" ? (
                    <div className="w-100">
                      <div className="pb-5 pb-lg-10">
                        {data.id > 0 ? (
                          <>
                            <h3 className="fw-bolder text-dark display-6">
                              Edit User ID: {data.id}
                            </h3>
                            <div className="text-muted fw-bold fs-3">
                              Edit the selected user account.
                            </div>
                          </>
                        ) : (
                          <>
                            <h3 className="fw-bolder text-dark display-6">
                              New User
                            </h3>
                            <div className="text-muted fw-bold fs-3">
                              Create a new user account.
                            </div>
                          </>
                        )}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                              ["is-invalid"]: !data.firstname && hasError,
                            }
                          )}
                          name="firstname"
                          placeholder=""
                          value={data.firstname}
                          onChange={(e) =>
                            updateData({ firstname: e.target.value })
                          }
                        />
                        {!data.firstname && hasError && (
                          <div className="invalid-feedback">
                            First name is required
                          </div>
                        )}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                              ["is-invalid"]: !data.lastname && hasError,
                            }
                          )}
                          name="lastname"
                          placeholder=""
                          value={data.lastname}
                          onChange={(e) =>
                            updateData({ lastname: e.target.value })
                          }
                        />
                        {!data.lastname && hasError && (
                          <div className="invalid-feedback">
                            Last name is required
                          </div>
                        )}
                      </div>

                      <div className="fv-row mb-8">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Email Address
                        </label>
                        <input
                          type="text"
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                              ["is-invalid"]: !data.email && hasError,
                            }
                          )}
                          name="email"
                          placeholder=""
                          value={data.email}
                          onChange={(e) =>
                            updateData({ email: e.target.value })
                          }
                        />
                        {!data.email && hasError && (
                          <div className="invalid-feedback">
                            Email address is required
                          </div>
                        )}
                      </div>

                      <div className="fv-row mb-8 d-none">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Username
                        </label>
                        <input
                          type="text"
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                              //['is-invalid']: (!data.username && hasError)
                            }
                          )}
                          name="username"
                          placeholder=""
                          readOnly={action === "edit"}
                          value={data.username}
                          onChange={(e) =>
                            updateData({ username: e.target.value })
                          }
                        />
                      </div>

                      <div
                        className="fv-row mb-8"
                        data-kt-password-meter="true"
                        id="kt_password_meter_control"
                        ref={meterRef}
                      >
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Password
                        </label>
                        <div className="position-relative mb-3">
                          <input
                            type="password"
                            className={clsx(
                              "form-control form-control-lg form-control-solid",
                              {
                                ["is-invalid"]: passwordInvalid,
                              }
                            )}
                            name="password"
                            placeholder=""
                            value={data.password}
                            onChange={(e) =>
                              updateData({ password: e.target.value })
                            }
                          />
                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-4"
                            data-kt-password-meter-control="visibility"
                          >
                            <i className="bi bi-eye-slash fs-2" />
                            <i className="bi bi-eye fs-2 d-none" />
                          </span>
                        </div>
                        {passwordInvalid && (
                          <div
                            className="invalid-feedback my-2"
                            style={{ display: "block" }}
                          >
                            Use 8 or more characters with a mix of
                            uppercase/lowercase letters, numbers & symbols.
                            Password must have a complexity score of at least
                            75% (3 bars).
                          </div>
                        )}
                        <div
                          className="d-flex align-items-center mb-3"
                          data-kt-password-meter-control="highlight"
                        >
                          <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                          <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                          <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                          <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px"></div>
                        </div>
                        {data.id > 0 ? (
                          <span className="form-text text-muted">
                            Leave blank if you do not wish to change this
                            account password.
                          </span>
                        ) : (
                          <span className="form-text text-muted">
                            If pre-registering an attendee, the user will choose
                            their own password during registration.
                          </span>
                        )}
                      </div>

                      <div className="fv-row">
                        <label className="d-flex align-items-center justify-flex-start mb-6 cursor-pointer">
                          <span className="form-check form-check-custom form-check-solid me-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="role"
                              value="admin"
                              checked={data.role === "admin"}
                              onChange={() => updateData({ role: "admin" })}
                            />
                          </span>
                          <span className="d-flex align-items-center me-2">
                            <span className="d-flex flex-column">
                              <span className="fw-bolder fs-6">
                                Administrator
                              </span>
                              <span className="fs-7 text-muted">
                                This account has access to the administrator
                                portal.
                              </span>
                            </span>
                          </span>
                        </label>

                        <label className="d-flex align-items-center justify-flex-start mb-6 cursor-pointer">
                          <span className="form-check form-check-custom form-check-solid me-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="role"
                              value="user"
                              checked={data.role === "user"}
                              onChange={() => updateData({ role: "user" })}
                            />
                          </span>
                          <span className="d-flex align-items-center me-2">
                            <span className="d-flex flex-column">
                              <span className="fw-bolder fs-6">Attendee</span>
                              <span className="fs-7 text-muted">
                                This account has access to the event frontend
                                only.
                              </span>
                            </span>
                          </span>
                        </label>

                        {!data.role && hasError && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            Please select a user role
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="d-flex justify-content-between pt-10">
                  <div>
                    <button
                      type="button"
                      className={clsx(
                        "btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3",
                        {
                          disabled: isLoading,
                          ["btn-danger"]: action === "delete",
                        }
                      )}
                      onClick={() => submit(data)}
                    >
                      {action === "delete" ? (
                        <span>Yes, Delete Permanently </span>
                      ) : action === "edit" ? (
                        <span>Save User Data </span>
                      ) : action === "new" ? (
                        <span>Save New User Account </span>
                      ) : null}
                      {!isLoading && (
                        <i
                          className={clsx("bi bi-save align-middle ms-2", {
                            ["bi-trash-fill"]: action === "delete",
                          })}
                        />
                      )}
                      {isLoading && (
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      )}
                    </button>
                  </div>
                  <div className="mr-2"></div>
                </div>
              </form>
            </div>

            {data.id > 0 && data.active === 0 && (
              <div className="d-flex w-auto flex-column text-center justify-content-start pt-2">
                <div className="pb-3 w-100 mw-200px">
                  <button
                    type="button"
                    className={clsx("btn btn-success", { disabled: isLoading })}
                    onClick={() => activate(data)}
                  >
                    <span>Activate User </span>
                    {!isLoading && (
                      <i
                        className={clsx(
                          "bi bi-person-check-fill align-middle ms-2"
                        )}
                      />
                    )}
                    {isLoading && (
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    )}
                  </button>
                </div>
                <div className="pb-3 w-100 mw-200px">
                  <span className="fs-7 text-muted">
                    This account has not verified their email and currently
                    cannot log in. Click above to manually activate the user.
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ModalUsers };
