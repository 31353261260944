import React, {useState, useRef, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'


type Props = {
	show: boolean
	handleClose: () => void
	handleSubmit: (data) => void
	thisData: any
	action: string
	isLoading: boolean
}

const ModalGeneral: React.FC<Props> = ({show, handleClose, handleSubmit, thisData, action, isLoading}) => {
	
	const [data, setData] = useState<{[key: string]: any}>({});
	const [hasError, setHasError] = useState(false)

	useEffect(() => {
    setData(thisData);
  }, [thisData]);
	
	useEffect(() => {
		
  }, [show])

	const updateData = (fieldsToUpdate: any) => {
		const updatedData = {...data, ...fieldsToUpdate}
		setData(updatedData)
	}

	const submit = (data) => {
		handleSubmit(data);
	}

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-end border-0'>
          <div className='btn btn-icon btn-sm btn-dark' onClick={handleClose}><i className="bi bi-x-lg" /></div>
        </div>

        <div className='modal-body'>
				
          <div className='d-flex flex-column flex-xl-row flex-row-fluid'>
						
            <div className='d-flex flex-row-fluid justify-content-center'>
						
              <form className='pb-5 w-100' noValidate>
							
                <div className='pb-5 current'>
                  
									{action === 'delete' ?
									
										<div className='w-100'>
											<div className='pb-5 pb-lg-10'>
												<h3 className='fw-bolder text-dark display-6'>Delete</h3>
												<div className='text-muted fw-bold fs-3'>
													Are you sure you want to delete this data?
												</div>
											</div>
										</div>
									
									: action === 'edit' || action === 'new' ?
									
									<div className='w-100'>
                    <div className='pb-5 pb-lg-10'>
											{data.id > 0 ?
												<>
		                      <h3 className='fw-bolder text-dark display-6'>Edit ID: {data.id}</h3>
													<div className='text-muted fw-bold fs-3'>
		                        Edit the selected data.
		                      </div>
												</>
											:
												<>
													<h3 className='fw-bolder text-dark display-6'>New</h3>
													<div className='text-muted fw-bold fs-3'>
														Create a new user account.
													</div>
												</>
											}
                    </div>
										
                    <div className='fv-row mb-8'>
                      <label className='fs-6 fw-bolder text-dark form-label'>First Name</label>
                      <input
                        type='text'
												className={clsx('form-control form-control-lg form-control-solid', {
													['is-invalid']: (!data.firstname && hasError)
												})}
                        name='firstname'
                        placeholder=''
                        value={data.firstname}
                        onChange={(e) =>
                          updateData({firstname: e.target.value})
                        }
                      />
                      {!data.firstname && hasError && (
                        <div className='invalid-feedback'>First name is required</div>
                      )}
                    </div>
										
                  </div>
									: null }
                </div>
								
								
									
                <div className='d-flex justify-content-between pt-10'>
                  <div>
                    <button
                      type='button'
											className={clsx('btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3', {
												disabled: isLoading,
												['btn-danger']: (action === 'delete')
											})}
                      onClick={() => submit(data)}
                    >
											{action === 'delete' ?
												<span>Yes, Delete Permanently{' '}</span>
											: action === 'edit' ?
												<span>Save Data{' '}</span>
											: action === 'new' ?
												<span>Save New Item{' '}</span>
											: null }
                      {!isLoading && <i className={clsx('bi bi-save align-middle ms-2', {['bi-trash-fill']: (action === 'delete')})} />}
											{isLoading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                    </button>
                  </div>
									<div className='mr-2'>
                  </div>
                </div>
								
              </form>
            </div>
						
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {ModalGeneral}
