import React from "react";
import { connect } from "react-redux";
import agent from "../agent";
import { Content } from "../widgets/components/Content";
import { Footer } from "../widgets/components/Footer";
import { ScrollTop } from "../widgets/components/ScrollTop";

import { LOAD_MEETING, LOAD_PDF } from "../constants/actionTypes";

const mapStateToProps = (state) => ({
  ...state.common,
  currentUser: state.common.currentUser,
  meeting: state.meeting.meeting,
  pdf: state.pdfs.pdf,
});

const mapDispatchToProps = (dispatch) => ({
  loadMeeting: (id) =>
    dispatch({ type: LOAD_MEETING, payload: agent.Meetings.loadMeeting(id) }),
  loadPdf: (id) =>
    dispatch({ type: LOAD_PDF, payload: agent.Pdfs.loadPdf(id) }),
});

function handleRightClick(event) {
  event.preventDefault();
}

class PdfFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match?.params?.id ? props.match.params.id : "",
      flowPaperLoaded: false,
      isFlowPaper: false,
    };
  }

  componentDidMount() {
    this.props.loadMeeting(1);
    this.props.loadPdf(this.state.id);
    this.handleUserAction({
      type: "load",
      action: "Meeting Secure PDF Loaded",
    });
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.pdf?.external_link !== this.props.pdf?.external_link) {
      const isFlowpaper = this.props.pdf?.external_link.includes("flowpaper");
      this.setState({ isFlowPaper: isFlowpaper });
      // this.loadFlowPaperScript();
    }
  }

  // loadFlowPaperScript = () => {
  //   if (this.state.flowPaperLoaded || !this.props.pdf?.external_link) return;

  //   // Wait 500ms before adding this, to give the DOM element time to render.
  //   setTimeout(() => {
  //     const script = document.createElement("script");
  //     script.src =
  //       "https://cdn-online.flowpaper.com/zine/3.8.5/js/embed.min.js";
  //     script.async = true;
  //     script.onload = () => this.setState({ flowPaperLoaded: true });
  //     document.body.appendChild(script);
  //   }, 5000);
  // };

  async handleUserAction(submitData) {
    await agent.Frontend.sendUserAction(submitData);
  }

  render() {
    const isFlowpaper = this.props.pdf?.external_link.includes("flowpaper");
    const pdf_url = this.props.pdf?.external_link
      ? isFlowpaper
        ? `https://live.revelstokecapital.com/pdf.html?file=${encodeURIComponent(
            this.props.pdf.external_link
          )}`
        : this.props.pdf.external_link
      : "";

    return (
      <>
        <div
          className="d-flex flex-column flex-root"
          onContextMenu={handleRightClick}
        >
          <div className="page d-flex flex-row flex-column-fluid">
            <div
              className="px-0 d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div
                id="kt_content"
                className="content d-flex flex-column flex-column-fluid home"
              >
                <Content style={{ padding: "0px", maxWidth: "1600px" }}>
                  <>
                    {this.props.meeting.active === 1 ||
                    this.props.currentUser.role === "admin" ? (
                      <div className="text-center">
                        <div className="box-responsive">
                          <iframe
                            src={pdf_url}
                            width="400"
                            height="300"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen=""
                            data-ready="true"
                            className="fp-embed"
                            data-fp-width="400px"
                            data-fp-height="300px"
                            style={{
                              maxWidth: "100%",
                              width: "100%",
                              height: "100%",
                              border: "none",
                            }}
                          ></iframe>
                        </div>
                      </div>
                    ) : null}
                  </>
                </Content>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ScrollTop />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfFile);
