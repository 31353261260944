import _superagent from "superagent";
import superagentPromise from "superagent-promise";

const superagent = superagentPromise(_superagent, global.Promise);
let API_ROOT =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_ROOT_PRODUCTION
    : process.env.REACT_APP_API_ROOT;

const responseBody = (res) => res.body;
const responseBodyFull = (res) => res;
let token = null;
const tokenPlugin = (req) => {
  if (token) {
    req.set("authorization", `Bearer ${token}`);
  }
};

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(console.error),
  get: (url, params) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .query(params)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(console.error),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(console.error),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(console.error),
  file: (url, file) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .attach("file", file)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(console.error),
  blob: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .responseType("blob")
      .then(responseBodyFull)
      .catch(console.error),
};

const Auth = {
  current: () => requests.post("refreshtoken", {}),
  login: (email, password, otpcode) =>
    requests.post("login", {
      email: email,
      password: password,
      otpcode: otpcode,
    }),
  logout: () => requests.get("logout", {}),
  log: (type, action) =>
    requests.post("log", { targetType: type, targetAction: action }),
  user: () => requests.get("profile", {}),
  reset: (currentStep, email, password, code) =>
    requests.post("reset", {
      currentStep: currentStep,
      email: email,
      password: password,
      code: code,
    }),
};

const Register = {
  register: (email, password, firstname, lastname) =>
    requests.post("register", {
      email: email,
      password: password,
      firstname: firstname,
      lastname: lastname,
    }),
};

const Frontend = {
  loadRegistrationWidget: () => requests.get("getusersbymonth", {}),
  sendSupportForm: (data) => requests.post("sendsupport", data),
  sendUserAction: (data) => requests.post("sendaction", data),
};

const Admin = {
  loadUsers: (page, limit, search, sort) =>
    requests.get("getusers", {
      page: page,
      limit: limit,
      search: search,
      sort: sort,
    }),
  loadUser: (id) => requests.get("getuser/" + id, {}),
  updateUser: (data) => requests.put("updateuser/" + data.id, data),
  newUser: (data) => requests.post("adduser", data),
  activateUser: (id) => requests.get("activateuser/" + id, {}),
  deleteUser: (id) => requests.del("users/delete/" + id),
  deleteUsers: (data) => requests.post("users/bulkdelete", data),
  importUsers: (data) => requests.file("users/import", data),
  purgeUsers: () => requests.del("users/purge"),
};

const Logs = {
  loadLogs: (page, limit, search) =>
    requests.get("logs/list", { page: page, limit: limit, search: search }),
  loadLog: (id) => requests.get("logs/get/" + id, {}),
  purgeLogs: () => requests.del("logs/purge"),
  exportLogs: () => requests.blob("logs/export"),
};

const Agendas = {
  loadAgendas: (page, limit, search, sortby, order) =>
    requests.get("agendas/list", {
      page: page,
      limit: limit,
      search: search,
      sortby: sortby ? sortby : "id",
      order: order ? order : "asc",
    }),
  loadAgenda: (id) => requests.get("agendas/get/" + id, {}),
  updateAgenda: (data) => requests.put("agendas/update/" + data.id, data),
  newAgenda: (data) => requests.post("agendas/add", data),
  //newAgendas: (data) => requests.post('agenda/bulkadd', data), //NOTE - For future.
  deleteAgenda: (id) => requests.del("agendas/delete/" + id),
  deleteAgendas: (data) => requests.post("agendas/bulkdelete", data),
  purgeAgendas: () => requests.del("agendas/purge"),
};

const Speakers = {
  loadSpeakers: (page, limit, search) =>
    requests.get("speakers/list", { page: page, limit: limit, search: search }),
  loadSpeaker: (id) => requests.get("speakers/get/" + id, {}),
  updateSpeaker: (data) => requests.put("speakers/update/" + data.id, data),
  newSpeaker: (data) => requests.post("speakers/add", data),
  uploadFile: (file) => requests.file("speakers/upload", file),
  deleteSpeaker: (id) => requests.del("speakers/delete/" + id),
  deleteSpeakers: (data) => requests.post("speakers/bulkdelete", data),
};

const Pdfs = {
  loadPdfs: (page, limit, search) =>
    requests.get("pdfs/list", { page: page, limit: limit, search: search }),
  loadPdf: (id) => requests.get("pdfs/get/" + id, {}),
  updatePdf: (data) => requests.put("pdfs/update/" + data.id, data),
  newPdf: (data) => requests.post("pdfs/add", data),
  deletePdf: (id) => requests.del("pdfs/delete/" + id),
  deletePdfs: (data) => requests.post("pdfs/bulkdelete", data),
};

const Meetings = {
  loadMeeting: (id) => requests.get("meeting/get/" + id, {}),
  updateMeeting: (data) => requests.put("meeting/update/" + data.id, data),
};

const Event = {
  loadEvent: (id) => requests.get("event/get/" + id, {}),
  updateEvent: (data) => requests.put("event/update/" + data.id, data),
};

const Oauth = {
  authorize: (type) => requests.post("oauthauthorize", { type: type }),
  access_token: (authorization_code) =>
    requests.post("oauthaccesstoken", {
      authorization_code: authorization_code,
    }),
};

export default {
  Auth,
  Register,
  Frontend,
  Admin,
  Oauth,
  Logs,
  Agendas,
  Speakers,
  Pdfs,
  Meetings,
  Event,
  setToken: (_token) => {
    token = _token;
  },
};
