import React from "react";
import { connect } from "react-redux";
import agent from "../agent";

import { AsideDefault } from "../widgets/components/aside/AsideDefault";
import { Content } from "../widgets/components/Content";
import { Footer } from "../widgets/components/Footer";
import { HeaderWrapper } from "../widgets/components/header/HeaderWrapper";
import { ScrollTop } from "../widgets/components/ScrollTop";
import { Toolbar } from "../widgets/components/toolbar/Toolbar";

import clsx from "clsx";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import { ListLoading } from "../widgets/helpers/ListLoading";

import {
  LOAD_MEETING,
  START_LOADING,
  STOP_LOADING,
  UPDATE_MEETING,
  baseUrl,
} from "../constants/actionTypes";

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  ...state.meeting,
});

const mapDispatchToProps = (dispatch) => ({
  loadMeeting: (id) =>
    dispatch({ type: LOAD_MEETING, payload: agent.Meetings.loadMeeting(id) }),
  updateMeeting: (data) => dispatch({ type: UPDATE_MEETING, payload: data }),
  startLoading: () => dispatch({ type: START_LOADING }),
  stopLoading: () => dispatch({ type: STOP_LOADING }),
});

const defaultSwalProps = {
  icon: "info",
  buttonsStyling: false,
  padding: "0 0 2.0em",
  confirmButtonText: "Yes, Delete",
  customClass: {
    confirmButton: "btn btn-lg btn-info mx-2",
    cancelButton:
      "btn btn-lg btn-light mx-2 btn-active-icon-dark btn-active-text-dark",
  },
  showCancelButton: true,
  cancelButtonText: "Wait! Cancel",
};

class AdminMeeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.timer = null;
    this.email_content = React.createRef();
  }

  componentDidMount() {
    this.props.loadMeeting(1);
  }

  componentWillUnmount() {
    if (this.email_content) {
      this.email_content = null;
    }
  }

  componentDidUpdate(prevProps) {}

  updateData = (fieldsToUpdate) => {
    let emailContentData = {};
    if (this.email_content.current) {
      emailContentData = { email_content: this.email_content.current.value };
    }
    const updatedData = {
      ...this.props.meeting,
      ...fieldsToUpdate,
      ...emailContentData,
    };
    this.props.updateMeeting(updatedData);
  };

  async submitData(submitData) {
    if (submitData.title === "") {
      this.setState({ hasError: true });
      Swal.fire({
        ...defaultSwalProps,
        html: "<h3 className='fw-bolder text-dark display-6'>Missing required fields.</h3>",
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
        customClass: { confirmButton: "btn btn-lg btn-danger mx-2" },
      });
      return;
    }
    const response = await agent.Meetings.updateMeeting(
      this.email_content.current
        ? {
            ...submitData,
            ...{ email_content: this.email_content.current.value },
          }
        : submitData
    );
    if (response?.success === true) {
      Swal.fire({
        ...defaultSwalProps,
        html: "<h3 className='fw-bolder text-dark display-6'>Meeting information has been updated.</h3>",
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
        customClass: { confirmButton: "btn btn-lg btn-success mx-2" },
      });
    } else {
      Swal.fire({
        ...defaultSwalProps,
        html: "<h3 className='fw-bolder text-dark display-6'>Connection failed, please try again.</h3>",
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
        customClass: { confirmButton: "btn btn-lg btn-danger mx-2" },
      });
      this.props.stopLoading();
    }
    this.setState({ hasError: false });
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
    "image",
  ];

  render() {
    const start_at = moment.utc(this.props.meeting.start_at).format(); //console.log(start_at, "- now in UTC");
    const local_start_at = moment.utc(start_at).local().toDate(); //console.log(local_start_at, "- UTC now to local");

    const end_at = moment.utc(this.props.meeting.end_at).format(); //console.log(start_at, "- now in UTC");
    const local_end_at = moment.utc(end_at).local().toDate(); //console.log(local_start_at, "- UTC now to local");

    let [, tzName] = /.*\s(.+)/.exec(
      new Date().toLocaleDateString(navigator.language, {
        timeZoneName: "short",
      })
    );

    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <AsideDefault />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <HeaderWrapper
                title="Meeting Information"
                currentUser={this.props.currentUser}
              />
              <Toolbar />
              <div
                id="kt_content"
                className="content d-flex flex-column flex-column-fluid"
              >
                <Content>
                  <div
                    className="subheader pb-2 pb-lg-4 subheader-transparent"
                    id="kt_subheader"
                  >
                    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                      <div className="d-flex align-items-center flex-wrap mr-2"></div>
                      <div className="d-flex align-items-center"></div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Meeting
                            <span className="d-block text-muted pt-2 font-size-sm">
                              Event/Meeting information.
                            </span>
                          </h3>
                        </div>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body">
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Meeting Status
                          </label>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="active"
                              checked={this.props.meeting.active === 1}
                              onChange={(e) =>
                                this.updateData({
                                  active: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="active"
                            >
                              Active?
                            </label>
                          </div>
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label required">
                            Meeting Title
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid",
                              {
                                ["is-invalid"]:
                                  !this.props.meeting.title &&
                                  this.state.hasError,
                              }
                            )}
                            type="text"
                            name="title"
                            placeholder=""
                            value={this.props.meeting.title}
                            onChange={(e) =>
                              this.updateData({ title: e.target.value })
                            }
                          />
                          {!this.props.meeting.title && this.state.hasError && (
                            <div className="invalid-feedback">
                              Meeting title is required.
                            </div>
                          )}
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label required">
                            Meeting Start Date & Time (in {tzName})
                          </label>
                          <DateTimePicker
                            name="start_at"
                            onChange={(e) => this.updateData({ start_at: e })}
                            value={local_start_at}
                            calendarClassName="card shadow-sm"
                            calendarIcon={
                              <i className="bi bi-calendar text-dark"></i>
                            }
                            clearIcon={
                              <i className="bi bi-x-square text-dark"></i>
                            }
                            className="form-control form-control-lg form-control-solid"
                            clockClassName=""
                          />
                          {!this.props.meeting.start_at &&
                            this.state.hasError && (
                              <div className="invalid-feedback">
                                Meeting Start Date & Time is required.
                              </div>
                            )}
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label required">
                            Meeting End Date & Time (in {tzName})
                          </label>
                          <DateTimePicker
                            name="end_at"
                            onChange={(e) => this.updateData({ end_at: e })}
                            value={local_end_at}
                            calendarClassName="card shadow-sm"
                            calendarIcon={
                              <i className="bi bi-calendar text-dark"></i>
                            }
                            clearIcon={
                              <i className="bi bi-x-square text-dark"></i>
                            }
                            className="form-control form-control-lg form-control-solid"
                            clockClassName=""
                          />
                          {!this.props.meeting.end_at &&
                            this.state.hasError && (
                              <div className="invalid-feedback">
                                Meeting End Date & Time is required.
                              </div>
                            )}
                        </div>
                        <div></div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Show Live Zoom Feed?
                          </label>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="livezoom"
                              checked={this.props.meeting.livezoom === 1}
                              onChange={(e) =>
                                this.updateData({
                                  livezoom: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="livezoom"
                            >
                              Yes
                            </label>
                          </div>
                        </div>

                        <div className="separator"></div>
                        <div className="text-black fw-semibold fs-3 mt-4 mb-4">
                          Meeting Zoom API
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Zoom API Key
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            type="text"
                            name="zoom_api_key"
                            placeholder=""
                            value={this.props.meeting.zoom_api_key}
                            onChange={(e) =>
                              this.updateData({ zoom_api_key: e.target.value })
                            }
                          />
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Zoom API Secret
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            type="text"
                            name="zoom_api_secret"
                            placeholder=""
                            value={this.props.meeting.zoom_api_secret}
                            onChange={(e) =>
                              this.updateData({
                                zoom_api_secret: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Meeting ID
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            type="text"
                            name="meetingid"
                            placeholder=""
                            value={this.props.meeting.meeting_id}
                            onChange={(e) =>
                              this.updateData({ meeting_id: e.target.value })
                            }
                          />
                        </div>

                        <div className="separator"></div>
                        <div className="text-black fw-semibold fs-3 mt-4 mb-4">
                          Vimeo Meeting Source
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Vimeo Source URL
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            type="text"
                            name="meeting_video_source"
                            placeholder=""
                            value={this.props.meeting.meeting_video_source}
                            onChange={(e) =>
                              this.updateData({
                                meeting_video_source: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Video Password
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            type="text"
                            name="meeting_video_password"
                            placeholder=""
                            value={this.props.meeting.meeting_video_password}
                            onChange={(e) =>
                              this.updateData({
                                meeting_video_password: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="separator"></div>
                        <div className="text-black fw-semibold fs-3 mt-4 mb-4">
                          New User Email Settings
                        </div>
                        <div className="fv-row mb-8 hidden">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Email From Name
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            type="text"
                            name="email_from_name"
                            placeholder=""
                            value={this.props.meeting.email_from_name}
                            onChange={(e) =>
                              this.updateData({
                                email_from_name: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="fv-row mb-8 hidden">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Email From Address
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            type="text"
                            name="email_from_address"
                            placeholder=""
                            value={this.props.meeting.email_from_address}
                            onChange={(e) =>
                              this.updateData({
                                email_from_address: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Email Subject
                          </label>
                          <input
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            type="text"
                            name="email_subject"
                            placeholder=""
                            value={this.props.meeting.email_subject}
                            onChange={(e) =>
                              this.updateData({ email_subject: e.target.value })
                            }
                          />
                        </div>
                        <div className="fv-row mb-8">
                          <label className="fs-6 fw-bolder text-dark form-label">
                            Email Message
                          </label>
                          <ReactQuill
                            ref={this.email_content}
                            theme="snow"
                            modules={this.modules}
                            formats={this.formats}
                            value={this.props.meeting.email_content}
                            rows={12}
                            cols={100}
                          />
                        </div>

                        <div className="d-flex justify-content-between pt-10">
                          <div>
                            <button
                              type="button"
                              className={clsx(
                                "btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3",
                                { disabled: this.props.isLoading }
                              )}
                              onClick={() =>
                                this.submitData(this.props.meeting)
                              }
                            >
                              <span>Save Meeting Data </span>
                              {!this.props.isLoading && (
                                <i
                                  className={clsx(
                                    "bi bi-save align-middle ms-2"
                                  )}
                                />
                              )}
                              {this.props.isLoading && (
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              )}
                            </button>
                          </div>
                          <div className="mr-2"></div>
                        </div>
                        {this.props.isLoading && <ListLoading />}
                      </div>
                    </div>
                  </div>
                </Content>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ScrollTop />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminMeeting);
