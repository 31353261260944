import React from "react";
import { connect } from "react-redux";

import { AsideDefault } from "../widgets/components/aside/AsideDefault";
import { Footer } from "../widgets/components/Footer";
import { HeaderWrapper } from "../widgets/components/header/HeaderWrapper";
import { Toolbar } from "../widgets/components/toolbar/Toolbar";
import { Content } from "../widgets/components/Content";

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

class Error404 extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <HeaderWrapper
                title="Page Not Found"
                pageType="user"
                currentUser={this.props.currentUser}
              />
              <div
                id="kt_content"
                className="content d-flex flex-column flex-column-fluid"
              >
                <Content>
                  <>
                    <h1 className="error-title font-weight-bold mt-8 mb-4 display-6">
                      Not Found!
                    </h1>
                    <p className="font-weight-boldest display-1 text-primary">
                      ERROR 404
                    </p>
                    <p className="font-size-h3">
                      The page you're trying to visit could not be found.
                    </p>
                  </>
                </Content>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error404);
