import {
	LOAD_MEETING,
	UPDATE_MEETING,
	STOP_LOADING,
	START_LOADING
} from "../constants/actionTypes";

const defaultState = {
	meeting: {
		id: 1,
		active: 0,
		title: '',
		start_at: new Date(),
		livezoom: 0,
		zoom_api_key: '',
		zoom_api_secret: '',
		meeting_id: '',
		meeting_video_source: '',
		meeting_video_password: '',
		email_from_name: '',
		email_from_address: '',
		email_subject: '',
		email_content: '',
	},
	isLoading: false
};

export default (state = defaultState, action) => {
	switch (action.type) {
		
		case START_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
			
		case STOP_LOADING:
			return { 
				...state, 
				isLoading: false,
			};
		
		case LOAD_MEETING:
			return { 
				...state,
				meeting: action.payload?.success === true ? action.payload.meeting[0] : defaultState.meeting,
				isLoading: false,
			};
			
		case UPDATE_MEETING:
			return { 
				...state,
				meeting: action.payload ? action.payload : defaultState.meeting,
				isLoading: false,
			};
			
		default:
			return state;
	}
};
