import {
	LOAD_AGENDAS,
	START_LOADING,
	STOP_LOADING
} from "../constants/actionTypes";

const defaultState = {
	headers: [
		{label:'Title',sort:true,active:false,dir:'asc'},
		{label:'Description',sort:false,active:false,dir:'asc'},
		{label:'Start Time',sort:true,active:true,dir:'asc'},
		{label:'End Time',sort:true,active:false,dir:'asc'},
		{label:'Speaker',sort:true,active:false,dir:'asc'},
		{label:'Active',sort:false,active:false,dir:'asc'},
		{label:'',sort:false,active:false,dir:'asc'}
	],
	pagination: {
		total: 0,
		current_page: 1,
		per_page: 20,
		links: []
	},
	agendas: [],
	currentPage: 1,
	currentLimit: 20,
	currentSearch: '',
	isLoading: false,
	agendaData: {
		start_at: new Date(),
		end_at: new Date(),
		speaker: '',
		title: '',
		desciption: '',
		active: 0,
		id: 0
	},
	action: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
		
		case START_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
			
		case STOP_LOADING:
			return { 
				...state, 
				isLoading: false,
			};
		
		case LOAD_AGENDAS:
			return { 
				...state,
				currentPage: action.payload?.success === true ? action.payload.pagination.current_page : 1,
				currentLimit: action.payload?.success === true ? action.payload.pagination.per_page : 10,
				currentSearch: action.payload?.success === true ? action.payload.search : '',
				agendas: action.payload?.success === true ? action.payload.agendas : [],
				pagination: action.payload?.success === true ? action.payload.pagination : defaultState.pagination,
				isLoading: false,
			};
			
    default:
      return state;
  }
};
