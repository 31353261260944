import ListErrors from './ListErrors';
import React from 'react';
import agent from '../agent';
import { connect } from 'react-redux';
import {
  OAUTH_TOKEN
} from '../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
	issue_token: (authorization_code) => dispatch({ type: OAUTH_TOKEN, payload: agent.Oauth.access_token(authorization_code) }),
  //onLogView: (action,area) => dispatch({ type: LOG_ACTION, payload: agent.Auth.log(action, area) })
});

class Callback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    //this.clickLogOut = this.clickLogOut.bind(this);
  }
  componentDidMount() {
		//console.log('props',this.props);
    this.setState({
      errors:this.props.location.state?this.props.location.state.errors:[]
    });
		let searchParams = new URLSearchParams(this.props.location.search);
		if(searchParams.has('code') === true) {
			this.props.issue_token(searchParams.get('code'));
		}
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  onRouteChanged() {
    //console.log("ROUTE CHANGED");
  }
  // clickLogOut() {
  //   this.props.onClickLogout();
  // }

  render() {    
    return (
      <div className="settings-page">
        <div className="container-fluid page">
          <div className="row">
            <div className="col-12">
              <div className="contentBox">
                {this.props.errorMessages ?
                  <ListErrors errors={this.props.errorMessages} />
                  :
                  <ListErrors errors={this.state.errors} />
                }
								<p>Logging in...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
