import React from 'react';
import agent from '../agent';
import { connect } from 'react-redux';

//import { PageTitle } from '../widgets/core';
import {AsideDefault} from '../widgets/components/aside/AsideDefault'
import {Footer} from '../widgets/components/Footer'
import {HeaderWrapper} from '../widgets/components/header/HeaderWrapper'
import {Toolbar} from '../widgets/components/toolbar/Toolbar'
import {RightToolbar} from '../widgets/partials/layout/RightToolbar'
import {ScrollTop} from '../widgets/components/ScrollTop'
import {Content} from '../widgets/components/Content';
//import {PageDataProvider} from './core'
//import {useLocation} from 'react-router-dom'
// import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../partials'

import { AdminRegistrations } from '../widgets/partials/widgets/AdminRegistrations';

import {
	LOAD_REGISTRATIONS_WIDGET,
	baseUrl
} from '../constants/actionTypes';
//import $ from 'jquery';

const mapStateToProps = state => ({
	currentUser: state.common.currentUser,
	...state.common,
});

const mapDispatchToProps = dispatch => ({
	loadRegistrationWidget: () => dispatch({ type: LOAD_REGISTRATIONS_WIDGET, payload: agent.Frontend.loadRegistrationWidget() }),
});

class AdminHome extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		this.props.loadRegistrationWidget();
		window.addEventListener('resize', this.updateWindowDimensions);
		//console.log('componentDidMount',this.props);
		//$("html, body").animate({ scrollTop: 0 }, "slow");
	}
	
	componentWillUnmount() {
		
	}
	
	componentDidUpdate(prevProps) {
		
	}

	render() {
		
		return (
			<>
				<div className='d-flex flex-column flex-root'>
					<div className='page d-flex flex-row flex-column-fluid'>
						<AsideDefault />
						<div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
							<HeaderWrapper title="Admin Dashboard" currentUser={this.props.currentUser} />
							<Toolbar />
							<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
								<Content>
									<div className='row'>
										<div className="col-xl-12">
											<AdminRegistrations
												className='card-custom bg-radial-gradient-primary gutter-b card-stretch'
												chartColor='primary'
												chartTitle='Administrator Dashboard'
												chartDescription='Current account registrations.'
												chartHeight='320'
												chartUsersNew={this.props.chartUsersNew}
												chartUsersTotal={this.props.chartUsersTotal}
												chartUsersLabels={this.props.chartUsersLabels}
												chartTotalNewUsers={this.props.chartTotalNewUsers}
												chartTotalUsers={this.props.chartTotalUsers}
											/>
										</div>
									</div>
								</Content>
							</div>
							<Footer />
						</div>
					</div>
				</div>
				<ScrollTop />
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
