import React from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'

const QuickLinks: React.FC = () => (
  <div className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px' data-kt-menu='true'>
    <div className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10' style={{backgroundImage: `url('${toAbsoluteUrl('/images/header-bg.png')}')`}}>
      <h3 className='text-white fw-bold mb-3'>Quick Links</h3>
      <span className='badge bg-primary py-2 px-3'>Administrator</span>
    </div>

    <div className='row g-0'>
      <div className='col-6'>
        <a href='/admin' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'>
          <i className='bi bi-person-badge-fill text-primary mb-2' />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Administration</span>
          <span className='fs-7 text-gray-400'>Dashboard</span>
        </a>
      </div>

      <div className='col-6'>
        <a href='/' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'>
          <i className='bi bi-calendar-fill text-primary mb-2' />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Client</span>
          <span className='fs-7 text-gray-400'>Event Dashboard</span>
        </a>
      </div>
			
    </div>

    <div className='py-2 text-center border-top'>
      <Link to='/' className='btn btn-color-gray-600 btn-active-color-primary'></Link>
    </div>
  </div>
)

export {QuickLinks}
