import React from "react";
import { connect } from "react-redux";
import agent from "../agent";
import { Content } from "../widgets/components/Content";
import { Footer } from "../widgets/components/Footer";
import { ScrollTop } from "../widgets/components/ScrollTop";

import { LOAD_MEETING } from "../constants/actionTypes";

const mapStateToProps = (state) => ({
  ...state.common,
  currentUser: state.common.currentUser,
  meeting: state.meeting.meeting,
});

const mapDispatchToProps = (dispatch) => ({
  loadMeeting: (id) =>
    dispatch({ type: LOAD_MEETING, payload: agent.Meetings.loadMeeting(id) }),
});

function handleRightClick(event) {
  event.preventDefault();
}

class MeetingVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match?.params?.id ? props.match.params.id : 1,
    };
  }

  componentDidMount() {
    this.props.loadMeeting(this.state.id);
    this.handleUserAction({
      type: "load",
      action: "Meeting Window Video Frame",
    });
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {}

  async handleUserAction(submitData) {
    await agent.Frontend.sendUserAction(submitData);
  }

  render() {
    let meeting_url = this.props.meeting.meeting_video_source
      ? this.props.meeting.meeting_video_source
      : "https://player.vimeo.com/video/627438345?h=c7461cdefd&amp;title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;autopause=0&amp;quality=720p&amp;app_id=122963";
    meeting_url += this.props.meeting.meeting_video_password
      ? "&password=" + this.props.meeting.meeting_video_password
      : "";

    return (
      <>
        <div
          className="d-flex flex-column flex-root"
          onContextMenu={handleRightClick}
        >
          <div className="page d-flex flex-row flex-column-fluid">
            <div
              className="px-0 d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div
                id="kt_content"
                className="content d-flex flex-column flex-column-fluid home"
              >
                <Content style={{ padding: "0px", maxWidth: "1600px" }}>
                  <>
                    {this.props.meeting.active === 1 ||
                    this.props.currentUser.role === "admin" ? (
                      <div className="text-center">
                        <div className="box-responsive">
                          <iframe
                            src={meeting_url}
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen=""
                            data-ready="true"
                            style={{ width: "100%", height: "100%" }}
                            onLoad={() =>
                              this.handleUserAction({
                                type: "load",
                                action: "Meeting Window Video Frame",
                              })
                            }
                          ></iframe>
                        </div>
                      </div>
                    ) : null}
                  </>
                </Content>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ScrollTop />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingVideo);
