import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = props => {
	if(props.currentUser?.role === 'admin') {
		if(props.path.includes('/login') || props.path.includes('/register') || props.path.includes('/callback')) { //If we came from the login or register page, application is open and user logged in.
			return (<Redirect to={{ pathname: "/admin", state: { from: props.path } }} />);
		}
		return (<Route exact={props.exact} path={props.path} component={props.component} params={props.params} />);

	} else {
		return (
			<Redirect
				to={{
					pathname: "/",
					state: { from: props.path }
				}}
			/>
		);
	}
};

export default AdminRoute;
