import React from 'react'
import {MenuItem} from './MenuItem'
//import {MenuInnerWithSub} from './MenuInnerWithSub'
//import {MegaMenu} from './MegaMenu'

export function MenuInner() {
	return (
		<>
			<MenuItem title='Meeting' to='/#meeting' />
			<MenuItem title='Agenda' to='/#agenda' />
			<MenuItem title='Help' to='/#help' />
		</>
	)
}
