import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import clsx from "clsx";

type Props = {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (data) => void;
  isLoading: boolean;
};

const ModalContact: React.FC<Props> = ({
  show,
  handleClose,
  handleSubmit,
  isLoading,
}) => {
  const blankData = {
    subject: "",
    message: "",
  };

  const [data, setData] = useState<{ [key: string]: any }>(blankData);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (show) {
      setData(blankData); //Reset data when form is opened.
    }
  }, [show]);

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkFields = (): boolean => {
    if (!data.subject) {
      return false;
    }
    if (!data.message) {
      return false;
    }
    return true;
  };

  const submit = (data) => {
    setHasError(false);
    if (!checkFields()) {
      setHasError(true);
      return;
    }
    handleSubmit(data);
  };

  return (
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-800px h-auto"
      show={show}
      onHide={handleClose}
    >
      <div className="container-xxl px-10 py-10">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          <div className="btn btn-icon btn-sm btn-dark" onClick={handleClose}>
            <i className="bi bi-x-lg" />
          </div>
        </div>

        <div className="modal-body">
          <div className="d-flex flex-column flex-xl-row flex-row-fluid">
            <div className="d-flex flex-row-fluid justify-content-center">
              <form className="pb-5 w-100" noValidate>
                <div className="pb-5 current">
                  <div className="w-100">
                    <div className="pb-5 pb-lg-10">
                      <>
                        <h3 className="fw-bolder text-dark display-6">
                          Contact
                        </h3>
                        <div className="text-muted fw-bold fs-3">
                          Send us a quick support message.
                        </div>
                      </>
                    </div>

                    <div className="fv-row mb-8">
                      <label className="fs-6 fw-bolder text-dark form-label required">
                        Subject / Topic
                      </label>
                      <input
                        type="text"
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          {
                            ["is-invalid"]: !data.subject && hasError,
                          }
                        )}
                        name="subject"
                        placeholder=""
                        value={data.subject}
                        maxLength={150}
                        onChange={(e) =>
                          updateData({ subject: e.target.value })
                        }
                      />
                      {!data.subject && hasError && (
                        <div className="invalid-feedback">
                          Subject is required
                        </div>
                      )}
                    </div>

                    <div className="fv-row mb-8">
                      <label className="fs-6 fw-bolder text-dark form-label required">
                        Message
                      </label>
                      <textarea
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          {
                            ["is-invalid"]: !data.message && hasError,
                          }
                        )}
                        name="message"
                        placeholder=""
                        value={data.message}
                        maxLength={2000}
                        onChange={(e) =>
                          updateData({ message: e.target.value })
                        }
                        rows={10}
                        cols={100}
                      />
                      {!data.message && hasError && (
                        <div className="invalid-feedback">
                          Message is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between pt-10">
                  <div>
                    <button
                      type="button"
                      className={clsx(
                        "btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3",
                        {
                          disabled: isLoading,
                        }
                      )}
                      onClick={() => submit(data)}
                    >
                      <span>Send Support Request </span>
                      {!isLoading && (
                        <i className={clsx("bi bi-save align-middle ms-2")} />
                      )}
                      {isLoading && (
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      )}
                    </button>
                  </div>
                  <div className="mr-2"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ModalContact };
