import React, {useState, useRef, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import DateTimePicker from 'react-datetime-picker'
import moment from 'moment'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

type Props = {
	show: boolean
	handleClose: () => void
	handleSubmit: (data) => void
	agendaData: any
	action: string
	isLoading: boolean
}

const ModalAgendas: React.FC<Props> = ({show, handleClose, handleSubmit, agendaData, action, isLoading}) => {
	
	const blankData = {
		starttime: '',
		endtime: '',
		speaker: '',
		title: '',
		desciption: '',
		active: 0,
		id: 0
	};
	
	const [data, setData] = useState<{[key: string]: any}>(blankData)
	const [hasError, setHasError] = useState(false)
	const [submitData, setSubmitData] = useState(false)
		
	const quill = useRef<ReactQuill | null>(null)
	
	useEffect(() => {
		console.log('useEffect', data);
		console.log('useEffect', submitData);
		if(submitData) {
			handleSubmit(data)
			setSubmitData(false)
		}
	}, [submitData]);

	useEffect(() => {
		//console.log(agendaData);
		setData(agendaData);
	}, [agendaData]);

	const updateData = (fieldsToUpdate: any) => {
		//console.log('updateData',data);console.log('updateData',fieldsToUpdate);
		let descData = {}
		if(quill.current) {
			descData = {desciption:quill.current.value}
		}
		const updatedData = {...data, ...fieldsToUpdate, ...descData}
		setData(updatedData)
	}
	
	
	const modules = {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline'],
			[{'list': 'ordered'}, {'list': 'bullet'}],
			['link'],
			['clean']
		],
	};

	const formats = [
		'header','bold', 'italic', 'underline','list', 'bullet','link'
	];
	
	const checkFields = (): boolean => {
		if (!data.title) {
		  return false
		}
		if (!data.start_at) {
		  return false
		}
		if (!data.end_at) {
		  return false
		}
		return true
	}

	const submit = (data) => {
		setHasError(false)
		if (!checkFields()) {
			setHasError(true)
			return
		}
		//handleSubmit(data);
		if(quill.current) {
			updateData({desciption:quill.current.value});//console.log(quill.current.value)
			setSubmitData(true);
		} else {
			return;
		}
	}
	
	const close = () => {
		setSubmitData(false);
		handleClose();
	}
	
	const start_at = moment.utc(data.start_at).format();//console.log(start_at, "- now in UTC");
	const local_start_at = moment.utc(start_at).local().toDate();//console.log(local_start_at, "- UTC now to local");
	
	const end_at = moment.utc(data.end_at).format();//console.log(start_at, "- now in UTC");
	const local_end_at = moment.utc(end_at).local().toDate();//console.log(local_start_at, "- UTC now to local");
	
	const tzName = new Date().toLocaleDateString(navigator.language, { timeZoneName: 'short' });
	const tzNameSplit = tzName.split(', ')[1];

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={close}
      //onEntered={loadStepper}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-end border-0'>
          <div className='btn btn-icon btn-sm btn-dark' onClick={close}><i className="bi bi-x-lg" /></div>
        </div>
				
				<div className='modal-body'>
				
					<div className='d-flex flex-column flex-xl-row flex-row-fluid'>
					
						<div className='d-flex flex-row-fluid justify-content-center'>
						
							<form className='w-100' noValidate>
							
								<div className='pb-3 current'>
								
									{action === 'edit' || action === 'new' ?
									
									<div className='w-100'>
										<div className='pb-5 pb-lg-10'>
											{data.id > 0 ?
												<>
													<h3 className='fw-bolder text-dark display-6'>Edit Agenda ID: {data.id}</h3>
													<div className='text-muted fw-bold fs-3'>Edit the selected schedule/agenda item.</div>
												</>
											:
												<>
													<h3 className='fw-bolder text-dark display-6'>New Agenda Item</h3>
													<div className='text-muted fw-bold fs-3'>Create a new schedule/agenda item.</div>
												</>
											}
										</div>
										
										<div className='row mb-8'>
											<div className="col-6">
												<label className='fs-6 fw-bolder text-dark form-label required'>Start Time (in {tzNameSplit})</label>
												<DateTimePicker
													name='start_at'
													onChange={(e) => updateData({start_at: e})} 
													value={local_start_at}
													calendarClassName='card shadow-sm'
													calendarIcon={<i className="bi bi-calendar text-dark"></i>}
													clearIcon={<i className="bi bi-x-square text-dark"></i>}
													className={clsx('form-control form-control-lg form-control-solid', {
														['is-invalid']: (!data.start_at && hasError)
													})}
													clockClassName=''
												/>
												{!data.start_at && hasError && (
													<div className='invalid-feedback'>Start time is required</div>
												)}
											</div>
											<div className="col-6">
												<label className='fs-6 fw-bolder text-dark form-label required'>End Time (in {tzNameSplit})</label>
												<DateTimePicker
													name='end_at'
													onChange={(e) => updateData({end_at: e})} 
													value={local_end_at}
													calendarClassName='card shadow-sm'
													calendarIcon={<i className="bi bi-calendar text-dark"></i>}
													clearIcon={<i className="bi bi-x-square text-dark"></i>}
													className={clsx('form-control form-control-lg form-control-solid', {
														['is-invalid']: (!data.end_at && hasError)
													})}
													clockClassName=''
												/>
												{!data.end_at && hasError && (
													<div className='invalid-feedback'>End time is required</div>
												)}
											</div>
                      
                    </div>
										
										<div className='fv-row mb-8'>
											<label className='fs-6 fw-bolder text-dark form-label'>Status</label>
											<div className="form-check form-switch form-check-custom form-check-solid">
												<input 
													className="form-check-input"
													type="checkbox"
													id="active"
													checked={(data.active === 1)}
													onChange={(e) => updateData({active: (e.target.checked)?1:0})}
												/>
												<label className="form-check-label" htmlFor="active">Active?</label>
											</div>
										</div>
										
										<div className='fv-row mb-8'>
                      <label className='fs-6 fw-bolder text-dark form-label required'>Agenda Title</label>
                      <input
                        type='text'
                        name='title'
                        placeholder=''
                        value={data.title}
                        onChange={(e) =>
                          updateData({title: e.target.value})
                        }
												className={clsx('form-control form-control-lg form-control-solid', {
													['is-invalid']: (!data.title && hasError)
												})}
                      />
											{!data.start_at && hasError && (
												<div className='invalid-feedback'>Title is required.</div>
											)}
                    </div>
										
										<div className='fv-row mb-8'>
                      <label className='fs-6 fw-bolder text-dark form-label'>Speaker</label>
                      <input
                        type='text'
												className={clsx('form-control form-control-lg form-control-solid')}
                        name='speaker'
                        placeholder=''
                        value={data.speaker}
                        onChange={(e) =>
                          updateData({speaker: e.target.value})
                        }
                      />
                    </div>
										
										<div className='fv-row mb-8'>
                      <label className='fs-6 fw-bolder text-dark form-label'>Agenda Description</label>
											<ReactQuill ref={quill} theme="snow" modules={modules} formats={formats} value={data.desciption} />
                    </div>
										
                  </div>
									: null }
                </div>
                <div className='d-flex justify-content-between pt-10'>
                  <div>
                    <button
                      type='button'
											className={clsx('btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3', {
												disabled: isLoading,
												['btn-danger']: (action === 'delete')
											})}
                      onClick={() => submit(data)}
                    >
											{action === 'edit' ?
												<span>Save Agenda{' '}</span>
											: action === 'new' ?
												<span>Save New Agenda Item{' '}</span>
											: null }
                      {!isLoading && <i className={clsx('bi bi-save align-middle ms-2')} />}
											{isLoading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                    </button>
                  </div>
									<div className='mr-2'></div>
                </div>
              </form>
            </div>
						
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {ModalAgendas}
