import React from 'react';
//import { Link } from 'react-router-dom';

class Loading extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="appLoading"></div>
      </div>
    );
  }
}

export default Loading;
