import { combineReducers } from "redux";
import auth from "./reducers/auth";
import common from "./reducers/common";
import users from "./reducers/users";
import logs from "./reducers/logs";
import meeting from "./reducers/meeting";
import agendas from "./reducers/agendas";
import speakers from "./reducers/speakers";
import pdfs from "./reducers/pdfs";
import groups from "./reducers/groups";

export default combineReducers({
  auth,
  common,
  users,
  logs,
  meeting,
  agendas,
  speakers,
  pdfs,
  groups,
});
