import React, { Component }  from 'react';
import clsx from 'clsx'

//[key: string]: any; //  allows dynamic keys and values
type Props = {
  pagination: any
	results: any[]
	clickPagination: any
	clickLimit: any
	isLoading: boolean
}

const ListPagination: React.FC<Props> = ({pagination, results, clickPagination, clickLimit, isLoading}) => {
	
	const updatePage = (page: number | null) => {
		if (!page || isLoading || pagination.current_page === page) {return;}
		clickPagination(page);
	}
	
	const updateLimit = (limit: string | null) => {
		if (!limit || isLoading || pagination.per_page === limit) {return;}
		clickLimit(limit);
	}

  return (
		<div className="d-flex justify-content-between align-items-center flex-wrap">
			<div className="d-flex flex-wrap mr-3">
				<a className="d-none btn btn-icon btn-sm btn-light-primary mr-2 my-1">
					<i className="bi bi-chevron-left icon-xs"></i>
				</a>
				{pagination.links?.map((link,index) => (
					<a
						key={link.label}
						className={clsx('btn btn-icon btn-sm btn-light border-0 btn-hover-primary mr-2 my-1', {
							['btn-primary']: (pagination.current_page === (index)),
							disabled: isLoading,
							['d-none']: (link.label === '&laquo; Previous' || link.label === 'Next &raquo;'),
						})}
						style={{cursor: 'pointer'}}
						onClick={() => updatePage(index)}
						dangerouslySetInnerHTML={{__html: link.label}}
					/>
				))}
				<a className="d-none btn btn-icon btn-sm btn-light-primary mr-2 my-1">
					<i className="bi bi-chevron-right icon-xs"></i>
				</a>
			</div>
			<div className="d-flex align-items-center">
				<select
					className="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light"
					style={{width:'75px'}}
					onChange={(e) => updateLimit(e.target.value)}
					value={pagination.per_page}
				>
					<option value="5">5</option>
					<option value="10">10</option>
					<option value="20">20</option>
					<option value="50">50</option>
					<option value="100">100</option>
					<option value="200">200</option>
				</select>
				<span className="text-muted">Displaying {results?.length} of {pagination?.total} records</span>
			</div>
		</div>
  )
}

export {ListPagination}
