import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  SETTINGS_SAVED,
  LOGIN,
  REGISTER,
  UPDATE_CURRENT_TIME,
  LOG_ACTION,
  LOAD_REGISTRATIONS_WIDGET,
  RECORD_SCORE,
  RECORD_CLICK,
  OAUTH_TOKEN,
  START_LOADING,
  STOP_LOADING,
} from "../constants/actionTypes";

const defaultState = {
  appName: "Revelstoke Capital",
  token: null,
  viewChangeCounter: 0,
  currentTime: Math.round(new Date().getTime() / 1000),
  isLoading: false,
  recordClick: 0,
  chartUsersNew: [0, 0, 0, 0, 0, 0, 0],
  chartUsersTotal: [0, 0, 0, 0, 0, 0, 0],
  chartUsersLabels: ["-", "-", "-", "-", "-", "-", "-"],
  chartTotalNewUsers: 0,
  chartTotalUsers: 0,
  currentUser: { email: "", firstname: "", lastname: "" },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.payload?.success ? action.payload.token : null,
        appLoaded: true,
        currentUser: action.payload?.success ? action.payload.user : null,
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case UPDATE_CURRENT_TIME:
      return { ...state, currentTime: Math.round(new Date().getTime() / 1000) };
    case LOG_ACTION:
      return {
        ...state,
      };
    case LOGOUT:
      return { ...state, redirectTo: "/login", token: null, currentUser: null };
    case SETTINGS_SAVED:
      return {
        ...state,
        redirectTo: action.error ? null : "/",
        currentUser: action.error ? null : action.payload?.data,
      };
    case LOGIN:
    case REGISTER:
    case OAUTH_TOKEN:
      //console.log(action.payload.error);
      //console.log('LOGIN',action.payload.message);
      return {
        ...state,
        redirectTo: action.payload?.success === true ? "/" : null,
        token: action.payload?.success === true ? action.payload.token : null,
        currentUser:
          action.payload?.success === true ? action.payload.user : null,
      };

    case LOAD_REGISTRATIONS_WIDGET:
      return {
        ...state,
        chartUsersNew:
          action.payload?.success === true
            ? action.payload.report.chartUsersNew
            : [0, 0, 0, 0, 0, 0, 0],
        chartUsersTotal:
          action.payload?.success === true
            ? action.payload.report.chartUsersTotal
            : [0, 0, 0, 0, 0, 0, 0],
        chartUsersLabels:
          action.payload?.success === true
            ? action.payload.report.chartUsersLabels
            : ["-", "-", "-", "-", "-", "-", "-"],
        chartTotalNewUsers:
          action.payload?.success === true
            ? action.payload.report.chartTotalNewUsers
            : 0,
        chartTotalUsers:
          action.payload?.success === true
            ? action.payload.report.chartTotalUsers
            : 0,
      };

    case RECORD_SCORE:
      return {
        ...state,
        responseMessage:
          action.payload?.success === true ? action.payload?.message : "",
      };
    case RECORD_CLICK:
      return { ...state, recordClick: action.payload + 1 };

    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
