import React from 'react';
import agent from '../agent';
import { connect } from 'react-redux';

import {AsideDefault} from '../widgets/components/aside/AsideDefault'
import {Footer} from '../widgets/components/Footer'
import {HeaderWrapper} from '../widgets/components/header/HeaderWrapper'
import {Toolbar} from '../widgets/components/toolbar/Toolbar'
import {ScrollTop} from '../widgets/components/ScrollTop'
import {Content} from '../widgets/components/Content';

import {ListLoading} from '../widgets/helpers/ListLoading'
import {ListPagination} from '../widgets/helpers/ListPagination'

import {ModalAgendas} from '../widgets/components/ModalAgendas'

import clsx from 'clsx'
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import Swal from 'sweetalert2'
import moment from 'moment'

import {
	START_LOADING,
	STOP_LOADING,
	LOAD_AGENDAS,
	baseUrl
} from '../constants/actionTypes';

const mapStateToProps = state => ({
	currentUser: state.common.currentUser,
	...state.agendas,
});

const mapDispatchToProps = dispatch => ({
	loadAgendas: (page,limit,search) => dispatch({ type: LOAD_AGENDAS, payload: agent.Agendas.loadAgendas(page,limit,search) }),
	startLoading: () => dispatch({ type: START_LOADING }),
	stopLoading: () => dispatch({ type: STOP_LOADING }),
});

const defaultSwalProps = {
	icon: "info",
	buttonsStyling: false,
	padding: '0 0 2.0em',
	confirmButtonText: "Yes, Delete",
	customClass: {
		confirmButton: "btn btn-lg btn-info mx-2",
		cancelButton: "btn btn-lg btn-light mx-2 btn-active-icon-dark btn-active-text-dark"
	},
	showCancelButton: true,
	cancelButtonText: 'Wait! Cancel',
};

const defaultAgenda = {
	start_at: new Date(),
	end_at: new Date(),
	speaker: '',
	title: '',
	desciption: '',
	active: 0,
	id: 0
};

class AdminAgendas extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			searchInputValue: '',
			modalShow: false,
			bulkActionsVisible: false,
			bulkChecks: [],
			agendaData: defaultAgenda,
			action: ''
		};
		this.timer = null;
	}
	
	componentDidMount() {
		this.props.startLoading();
		this.props.loadAgendas(this.props.currentPage,this.props.currentLimit,this.props.currentSearch);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentWillUnmount() {}
	
	componentDidUpdate(prevProps) {}
	
	clickSearch = (searchText) => {
		this.setState({searchInputValue: searchText});
		if(searchText.length < 2){return;}
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.props.startLoading();
			this.props.loadAgendas(this.props.currentPage,this.props.currentLimit,searchText);
			this.setState({bulkActionsVisible: false, bulkChecks: []});
		}, 750);
	}
	
	resetSearch = () => {
		this.props.startLoading();
		this.props.loadAgendas(1,10,'');
		this.setState({searchInputValue: '',bulkActionsVisible: false, bulkChecks: []});
	}
	
	sortHeading = () => {
		
	}
	
	async editRow(id) {
		if(id > 0) {
			this.props.startLoading();
			const response = await agent.Agendas.loadAgenda(id);
			if(response?.success === true) {
				const updatedData = {...this.state.agendaData, ...response.agenda[0] }
				this.setState({agendaData:response.agenda[0], modalShow: true, action: 'edit' });
				this.props.stopLoading();
			} else {
				Swal.fire({...defaultSwalProps,
					html: "<h3 className='fw-bolder text-dark display-6'>Connection failed, please try again.</h3>",
					icon: "error",confirmButtonText: "OK",showCancelButton: false,
					customClass: {confirmButton: "btn btn-lg btn-danger mx-2"}
				});
				this.props.stopLoading();
			}
		} else {
			const newData = {...this.state.agendaData, ...defaultAgenda }
			this.setState({agendaData:newData, modalShow: true, action: 'new' });
		}
	}
	
	deleteRow(id) {
		Swal.fire({...defaultSwalProps,
			html: "<h3 className='fw-bolder text-dark display-6'>Delete Agenda ID: "+id+"</h3><div className='text-muted fw-bold fs-3'>Are you sure you want to delete this agenda item?</div>"
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.startLoading();
				this.processDeleteRow(id);
			}
		})
	}
	
	async processDeleteRow(id) {
		const response = await agent.Agendas.deleteAgenda(id);
		if(response?.success === true) {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Agenda item has been deleted.</h3>",
				icon: "success",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-success mx-2"}
			});
			this.props.loadAgendas(this.props.currentPage,this.props.currentLimit,this.props.currentSearch);
			this.setState({bulkActionsVisible: false, bulkChecks: []});
		} else {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Connection failed, please try again.</h3>",
				icon: "error",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-danger mx-2"}
			});
			this.props.stopLoading();
		}
	}
	
	deleteAgendas(ids) {
		if(ids.length) {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Delete Agendas</h3><div className='text-muted fw-bold fs-3'>Are you sure you want to delete the selected agenda items?</div>"
			}).then((result) => {
				if (result.isConfirmed) {
					this.props.startLoading();
					this.processDeleteAgendas(ids);
				}
			})
		}
	}
	
	async processDeleteAgendas(ids) {
		const response = await agent.Agendas.deleteAgendas(ids);
		if(response?.success === true) {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Agendas have been deleted.</h3>",
				icon: "success",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-success mx-2"}
			});
			this.props.loadAgendas(this.props.currentPage,this.props.currentLimit,this.props.currentSearch);
			this.setState({bulkActionsVisible: false, bulkChecks: []});
		} else {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Connection failed, please try again.</h3>",
				icon: "error",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-danger mx-2"}
			});
			this.props.stopLoading();
		}
	}
	
	deleteAll() {
		Swal.fire({...defaultSwalProps,
			html: "<h3 className='fw-bolder text-dark display-6'>Delete All Agendas</h3><div className='text-muted fw-bold fs-3'>Are you sure you want to purge all agenda items from the database? This cannot be reversed.</div>"
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.startLoading();
				this.processDeleteAll();
			}
		})
	}
	
	async processDeleteAll() {
		const response = await agent.Agendas.purgeAgendas();
		if(response?.success === true) {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>All agenda items have been purged/deleted.</h3>",
				icon: "success",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-success mx-2"}
			});
			this.props.loadAgendas(this.props.currentPage,this.props.currentLimit,this.props.currentSearch);
			this.setState({bulkActionsVisible: false, bulkChecks: []});
		} else {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Connection failed, please try again.</h3>",
				icon: "error",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-danger mx-2"}
			});
			this.props.stopLoading();
		}
	}
	
	async handleSubmit(submitData) {
		this.props.startLoading();
		let that = this;
		let response = null;
		if(submitData.id > 0) {
			response = await agent.Agendas.updateAgenda(submitData);
		} else {
			response = await agent.Agendas.newAgenda(submitData);
		}
		if(response?.success === true) {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Agenda has been saved.</h3>",
				icon: "success",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-success mx-2"}
			});
			that.props.loadAgendas(that.props.currentPage,that.props.currentLimit,that.props.currentSearch);
			that.setState({modalShow: false, bulkActionsVisible: false, bulkChecks: []});
		} else {
			let message = (response?.message)?response.message:'Connection failed, please try again.';
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>"+message+"</h3>",
				icon: "error",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-danger mx-2"}
			});
			that.props.stopLoading();
		}
	}
	
	handleModalClose = () => {
		this.setState({modalShow: false});
	}
	
	clickPagination = (pageNumber) => {
		this.props.startLoading();
		this.props.loadAgendas(pageNumber,this.props.currentLimit,this.props.currentSearch);
		this.setState({bulkActionsVisible: false, bulkChecks: []});
	}
	
	clickLimit = (limitNumber) => {
		this.props.startLoading();
		this.props.loadAgendas(this.props.currentPage,limitNumber,this.props.currentSearch);
		this.setState({bulkActionsVisible: false, bulkChecks: []});
	}
	
	toggleSelectAll = (e) => {
		let newBulkChecks = [];
		Array.from(document.querySelectorAll(".table-responsive input[type=checkbox].rowSelector")).forEach((checkbox) => {
			document.getElementById(checkbox.id).checked = e.target.checked;
			if(e.target.checked) {newBulkChecks.push(document.getElementById(checkbox.id).value);}
		});
		this.setState({bulkActionsVisible: e.target.checked, bulkChecks: newBulkChecks});
	}
	
	toggleSelect = (e) => {
		let currentBulkChecks = this.state.bulkChecks;
		if(e.target.checked) {
			currentBulkChecks.push(e.target.value);
		} else {
			currentBulkChecks = currentBulkChecks.filter(item => item !== e.target.value);
			document.getElementById('check-all').checked = false;
		}
		let currentBulkActionsVisible = (currentBulkChecks.length > 0)?true:false;
		this.setState({bulkActionsVisible: currentBulkActionsVisible,bulkChecks:currentBulkChecks});
	}

	render() {
		
		let [, tzName] = /.*\s(.+)/.exec((new Date()).toLocaleDateString(navigator.language, { timeZoneName: 'short' }));
		
		return (
			<>
				<div className='d-flex flex-column flex-root'>
					<div className='page d-flex flex-row flex-column-fluid'>
						<AsideDefault />
						<div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
							<HeaderWrapper title="Agendas Management" currentUser={this.props.currentUser} />
							<Toolbar />
							<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
								<Content>
									<div className="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
										<div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
											<div className="d-flex align-items-center flex-wrap mr-2">
												<h5 className="text-dark font-weight-bold mt-2 mb-2">Search:</h5>
												<div className="d-flex align-items-center">
													<div className="ml-3">
														<div className="input-group input-group-md" style={{minWidth: '280px'}}>
															<input type="text" value={this.state.searchInputValue} className="form-control" placeholder="Enter title or speaker name..." onChange={(e) => this.clickSearch(e.target.value)} />
															{this.props.currentSearch !== ''?
																<div className="input-group-append">
																	<a className="btn btn-danger btn-icon" onClick={() => this.resetSearch()}>
																		<i className="bi bi-x-circle-fill"></i>
																	</a>
																</div>
															:
																<div className="input-group-append">
																	<a className="btn btn-primary btn-icon" onClick={(e) => this.clickSearch(this.state.searchInputValue)}>
																		<i className="bi bi-search"></i>
																	</a>
																</div>
															}
														</div>
													</div>
												</div>
												<div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
												<div className="d-flex align-items-center">
													{this.props.currentSearch !== ''?
														<span className="text-dark-50 font-weight-bold" id="kt_subheader_total">{this.props.pagination?.total} results for "{this.props.currentSearch}"...</span>
													: null }
												</div>
											</div>
											<div className="d-flex align-items-center">
												<a className="btn btn-bg-white btn-text-muted btn-active-info btn-sm px-4 mx-2"><i className="bi bi-calendar mr-2" />Time After</a>
												<a className="btn btn-bg-white btn-text-muted btn-active-info btn-sm px-4 mx-2 filter">
													<i className="bi bi-calendar mr-2" />Time Before
													<div className={clsx('card filterDrop text-start p-4 w-lg-225px',
														{show: false}
													)}>
														<a className="py-2 d-block" href="#">Admin</a>
														<a className="py-2 d-block" href="#">User</a>
													</div>
												</a>
											</div>
											{this.props.currentSearch !== '' || this.props.pagination.per_page !== 20 || this.props.pagination.current_page !== 1 ?
											<div className="d-flex align-items-center">
												<a className="btn btn-white btn-sm px-4 font-size-base ml-2" onClick={() => this.resetSearch()}><i className="bi bi-x-circle-fill"></i> Reset View</a>
											</div>
											: null }
										</div>
									</div>
									<div className="d-flex flex-column">
										<div className="card card-custom">
											<div className="card-header flex-wrap border-0 pt-6 pb-0">
												<div className="card-title">
													<h3 className="card-label">Agenda
													<span className="d-block text-muted pt-2 font-size-sm">Schedule of agenda items within the event.</span></h3>
												</div>
												<div className="card-toolbar">
													<a onClick={() => this.editRow(0)} className="btn btn-primary font-weight-bolder mx-2">Add New Agenda Item</a>
												</div>
											</div>
											<div className="card-body">
												<div className='table-responsive'>
													<table id='kt_table_agendas' className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
														<thead>
															<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
																<th>
																<OverlayTrigger placement='right' delay={{show: 200, hide: 400}} overlay={<Tooltip>Toggle Select All</Tooltip>}>
																	<div className="form-check form-check-custom form-check-sm"><input className="form-check-input" type="checkbox" value="all" id="check-all" onClick={(e) => this.toggleSelectAll(e)} /></div>
																</OverlayTrigger>
																</th>
																{this.props.headers.map((column,index) => {
																	if(column.sort) {
																		return (
																			<th key={index} onClick={() => this.sortHeading()}>
																				<OverlayTrigger placement='right' delay={{show: 200, hide: 400}} overlay={<Tooltip>Sort by {column.label}</Tooltip>}>
																					<span className="d-flex text-info" style={{cursor:'pointer'}}>{column.label}<i className={clsx('bi text-info ml-2',{['bi-sort-down']:(column.active && column.dir==='asc'),['bi-sort-up']:(column.active && column.dir==='desc')})} /></span>
																				</OverlayTrigger>
																			</th>
																		);
																	} else {
																		return (
																			<th key={index}>
																				<span>{column.label}</span>
																			</th>
																		);
																	}
																})}
															</tr>
														</thead>
														<tbody className='text-gray-600 fw-bold'>
															{this.props.agendas?.length > 0 ? (
																this.props.agendas.map((row) => {
																	//row = prepareRow(row,['edit','delete']);
																	let start = moment.utc(row.start_at).local().format('MMM. Do YYYY, h:mm A');
																	let end = moment.utc(row.end_at).local().format('MMM. Do YYYY, h:mm A');
																	return (
																		<tr key={row.id}>
																			<td>
																				<div className="form-check form-check-custom form-check-sm">
																					<input className="form-check-input rowSelector" id={'check-'+row.id} type="checkbox" value={row.id} onClick={(e) => this.toggleSelect(e)} />
																				</div>
																			</td>
																			<td>{row.title}</td>
																			<td>{row.desciption}</td>
																			<td>{start} {tzName}</td>
																			<td>{end} {tzName}</td>
																			<td>{row.speaker}</td>
																			<td>{(row.active === 1)?
																				<span className="badge badge-light-success fs-8 px-2 py-1">Yes</span>
																			:
																				<span className="badge badge-light fs-8 px-2 py-1">No</span>
																			}</td>
																			<td className="d-flex align-items-center justify-content-end">
																				<a onClick={() => this.editRow(row.id)} className="btn btn-icon btn-sm btn-primary mr-2"><i className="bi bi-pencil-fill"></i></a>
																				<a onClick={() => this.deleteRow(row.id)} className="btn btn-icon btn-sm btn-outline btn-outline-danger text-hover-white mr-2"><i className="bi bi-trash-fill text-danger"></i></a>
																			</td>
																		</tr>
																	);
																})
															) : (
																<tr>
																	<td colSpan={8}>
																		<div className='d-flex text-center w-100 align-content-center justify-content-center'>No results found.</div>
																	</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
												<ListPagination pagination={this.props.pagination} results={this.props.agendas} clickPagination={this.clickPagination} clickLimit={this.clickLimit} isLoading={this.props.isLoading} />
												{this.props.isLoading && <ListLoading />}
												{this.state.bulkActionsVisible ?
												<div className="bulkActions card card-flush card-dashed bg-white shadow-sm mb-0">
													<div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
														<div className="card-body py-3 px-3 text-center">
															<a onClick={() => this.deleteAgendas(this.state.bulkChecks)} className="btn btn-sm btn-primary btn-danger btn-text-white mx-2">Delete Selected <i className="bi bi-trash-fill" /></a>
														</div>
													</div>
												</div>
												: null }
											</div>
										</div>
									</div>
								</Content>
							</div>
							<Footer />
						</div>
					</div>
				</div>
				<ModalAgendas show={this.state.modalShow} handleSubmit={this.handleSubmit} handleClose={this.handleModalClose} agendaData={this.state.agendaData} action={this.state.action} isLoading={this.props.isLoading} />
				<ScrollTop />
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAgendas);
