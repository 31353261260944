import React from "react";
import { Link } from "react-router-dom";
import ListErrors from "./ListErrors";
import ListSuccess from "./ListSuccess";
import agent from "../agent";
import { connect } from "react-redux";
import queryString from "query-string";
import clsx from "clsx";
import { PasswordMeterComponent } from "../widgets";
//import $ from 'jquery';
import { LOADING, REGISTER, baseUrl } from "../constants/actionTypes";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (email, password, firstname, lastname) => {
    dispatch({
      type: REGISTER,
      payload: agent.Register.register(email, password, firstname, lastname),
    });
  },
});

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      password2: "",
      errors: null,
    };
    this.meterRef = React.createRef();
    this.passwordMeter = React.createRef();
  }

  componentWillUnmount() {}

  componentDidMount() {
    //const params = queryString.parse(this.props.location.search);//console.log(params);
    PasswordMeterComponent.bootstrap();
    this.loadMeter();
  }

  loadMeter = () => {
    this.meterRef.current = document.querySelector(
      "#kt_password_meter_control"
    );
    this.passwordMeter.current = PasswordMeterComponent.getInstance(
      this.meterRef.current
    );
  };

  submitForm = () => (ev) => {
    ev.preventDefault();
    let newErrors = [];
    if (this.state.email === "") {
      newErrors.push("Email is required.");
    }
    if (this.state.password === "") {
      newErrors.push("Password is required.");
    }
    if (this.state.password2 !== this.state.password) {
      newErrors.push("Passwords do not match.");
    }
    if (this.passwordMeter.current) {
      if (
        this.passwordMeter.current.getScore() < 100 ||
        this.passwordMeter.current.getScore() === null ||
        this.state.password.length < 6
      ) {
        newErrors.push(
          "Passwords is not strong enough, please provide at least one uppercase, one lowercase, one number and one special symbol. Minimum 6 characters."
        );
      }
    }
    this.setState({ errors: newErrors });
    if (newErrors.length === 0) {
      this.setState({ errors: null });
      this.props.onSubmit(
        this.state.email,
        this.state.password,
        this.state.firstname,
        this.state.lastname
      );
    } //console.log(this.state);
  };

  render() {
    const logoUrl = baseUrl + "images/splash-logo.svg";
    return (
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" className="mb-12">
            <img alt="Revelstoke Capital" src={logoUrl} className="h-65px" />
          </a>
          <div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form
              className="form w-100"
              onSubmit={this.submitForm()}
              noValidate
            >
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">User Registration</h1>
                <div className="text-gray-400 fw-bold fs-4">
                  Please register to access the event
                </div>
              </div>

              {this.props.successMessage ? (
                <ListSuccess errors={this.props.successMessage} />
              ) : null}

              {this.props.errorMessage ? (
                <ListErrors errors={this.props.errorMessage} />
              ) : null}

              {this.state.errors ? (
                <ListErrors errors={this.state.errors} />
              ) : null}

              {this.props.successMessage ? (
                <Link to="/">
                  <button
                    type="button"
                    className="btn btn-md btn-primary w-100 mb-5"
                  >
                    Back to Log In
                  </button>
                </Link>
              ) : (
                <>
                  <div className="row fv-row mb-7">
                    <div className="col-xl-6">
                      <label className="form-label fw-bolder text-dark fs-6">
                        First Name
                      </label>
                      <input
                        placeholder="First Name"
                        type="text"
                        value={this.state.firstname}
                        onChange={(event) =>
                          this.setState({ firstname: event.target.value })
                        }
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          { "is-invalid": 1 == 2 },
                          { "is-valid": 1 == 2 }
                        )}
                      />
                    </div>
                    <div className="col-xl-6">
                      <label className="form-label fw-bolder text-dark fs-6">
                        Last Name
                      </label>
                      <input
                        placeholder="Last Name"
                        type="text"
                        value={this.state.lastname}
                        onChange={(event) =>
                          this.setState({ lastname: event.target.value })
                        }
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          { "is-invalid": 1 == 2 },
                          { "is-valid": 1 == 2 }
                        )}
                      />
                    </div>
                  </div>

                  <div className="fv-row mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Email Address
                    </label>
                    <input
                      placeholder="Email Address"
                      type="email"
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        { "is-invalid": 1 == 2 },
                        { "is-valid": 1 == 2 }
                      )}
                      value={this.state.email}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    />
                  </div>

                  <div
                    className="fv-row mb-10"
                    data-kt-password-meter="true"
                    ref={this.meterRef}
                    id="kt_password_meter_control"
                  >
                    <div className="mb-1">
                      <label className="form-label fw-bolder text-dark fs-6">
                        Password
                      </label>
                      <div className="position-relative mb-3">
                        <input
                          type="password"
                          autoComplete="off"
                          className={clsx(
                            "form-control form-control-lg form-control-solid",
                            { "is-invalid": 1 == 2 },
                            { "is-valid": 1 == 2 }
                          )}
                          value={this.state.password}
                          onChange={(event) =>
                            this.setState({ password: event.target.value })
                          }
                        />
                      </div>
                      <div
                        className="d-flex align-items-center mb-3"
                        data-kt-password-meter-control="highlight"
                      >
                        <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-gray-200 bg-active-success rounded h-5px"></div>
                      </div>
                    </div>
                    <div className="text-muted fs-7">
                      At least one uppercase, one lowercase, one number and one
                      special symbol. Minimum 6 characters.
                    </div>
                  </div>

                  <div className="fv-row mb-10">
                    <div className="d-flex justify-content-between mt-n5">
                      <div className="d-flex flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">
                          Confirm Password
                        </label>
                      </div>
                    </div>
                    <input
                      type="password"
                      autoComplete="off"
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        { "is-invalid": 1 == 2 },
                        { "is-valid": 1 == 2 }
                      )}
                      value={this.state.password2}
                      onChange={(event) =>
                        this.setState({ password2: event.target.value })
                      }
                    />
                  </div>

                  {1 == 2 ? (
                    <div className="fv-row mb-10">
                      <div className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="kt_login_toc_agree"
                        />
                        <label
                          className="form-check-label fw-bold text-gray-700 fs-6"
                          htmlFor="kt_login_toc_agree"
                        >
                          I Agree the{" "}
                          <Link to="/terms" className="ms-1 link-primary">
                            terms and conditions
                          </Link>
                          .
                        </label>
                      </div>
                    </div>
                  ) : null}

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 mb-5"
                      disabled={this.props.inProgress}
                    >
                      {!this.props.inProgress && (
                        <span className="indicator-label">Register</span>
                      )}
                      {this.props.inProgress && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>

                    <div className="text-center text-muted text-uppercase fw-bolder mb-5">
                      -
                    </div>

                    <Link to="/">
                      <button
                        type="button"
                        className="btn btn-md bg-gray-300 bg-hover-lighten w-100 mb-5"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
