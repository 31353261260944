import React, {useEffect, useRef} from 'react'

import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from './'

import {useLayout} from './core'

export function MasterInit() {
  const {config} = useLayout()
  const isFirstRun = useRef(true)
  const pluginsInitialization = () => {
    isFirstRun.current = false
    setTimeout(() => {
      ToggleComponent.bootstrap()
      ScrollTopComponent.bootstrap()
      DrawerComponent.bootstrap()
      StickyComponent.bootstrap()
      MenuComponent.bootstrap()
      ScrollComponent.bootstrap()
      SwapperComponent.bootstrap()
			//alert(1);
    }, 500)
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      pluginsInitialization()
    }
		return () => {
			//console.log('cleaned up');
			MenuComponent.bootstrap()
			//console.log(MenuComponent.getElement());
			//console.log(MenuComponent.getInstance());
			// MenuComponent.destroy();
			// MenuComponent.reinitialization();
			//MenuComponent.getInstance()
			//MenuComponent.getElement()
    };
  }, [config])

  return <></>
}
