import clsx from "clsx";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { DrawerComponent } from "../";
import { useLayout } from "../core";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const Content: React.FC<Props> = ({ children, style }) => {
  const { classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div
      id="kt_content_container"
      className={clsx(classes.contentContainer.join(" "), "container-xxl")}
      style={style}
    >
      {children}
    </div>
  );
};

export { Content };
