import React from 'react';
import agent from '../agent';
import { connect } from 'react-redux';

import {AsideDefault} from '../widgets/components/aside/AsideDefault'
import {Footer} from '../widgets/components/Footer'
import {HeaderWrapper} from '../widgets/components/header/HeaderWrapper'
import {Toolbar} from '../widgets/components/toolbar/Toolbar'
import {ScrollTop} from '../widgets/components/ScrollTop'
import {Content} from '../widgets/components/Content';

import {ListLoading} from '../widgets/helpers/ListLoading'
import {ListPagination} from '../widgets/helpers/ListPagination'

import {ModalGeneral} from '../widgets/components/ModalGeneral'
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import moment from 'moment'
import Swal from 'sweetalert2'

import clsx from 'clsx'

import {
	START_LOADING,
	LOAD_LOGS,
	PURGE_LOGS,
	baseUrl
} from '../constants/actionTypes';

const mapStateToProps = state => ({
	currentUser: state.common.currentUser,
	...state.logs,
});

const mapDispatchToProps = dispatch => ({
	loadLogs: (page,limit,search) => dispatch({ type: LOAD_LOGS, payload: agent.Logs.loadLogs(page,limit,search) }),
	purgeLogs: () => dispatch({ type: PURGE_LOGS, payload: agent.Logs.purgeLogs() }),
	startLoading: () => dispatch({ type: START_LOADING }),
});

const defaultSwalProps = {
	icon: "info",
	buttonsStyling: false,
	padding: '0 0 2.0em',
	confirmButtonText: "Yes, Delete",
	customClass: {
		confirmButton: "btn btn-lg btn-info mx-2",
		cancelButton: "btn btn-lg btn-light mx-2 btn-active-icon-dark btn-active-text-dark"
	},
	showCancelButton: true,
	cancelButtonText: 'Wait! Cancel',
};

class AdminLogs extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			searchInputValue: '',
			modalShow: false
		};
		this.timer = null;
	}
	
	componentDidMount() {
		this.props.loadLogs(this.props.currentPage,this.props.currentLimit,this.props.currentSearch);
	}
	
	componentWillUnmount() {}
	
	componentDidUpdate(prevProps) {}
	
	clickSearch = (searchText) => {
		this.setState({searchInputValue: searchText});
		if(searchText.length < 2){return;}
		clearTimeout(this.timer);
    this.timer = setTimeout(() => {
			this.props.startLoading();
			this.props.loadLogs(this.props.currentPage,this.props.currentLimit,searchText);
    }, 750);
	}
	
	resetSearch = () => {
		this.props.startLoading();
		this.props.loadLogs(1,10,'');
		this.setState({searchInputValue: ''});
	}
	
	sortHeading = () => {
		
	}
	
	handleSubmit = (submitData) => {
		if(submitData) {
			this.setState({modalShow: false});
			//Show success, toast?
		} else {
			//Show error, toast?
		}
	}
	
	purgeLogs() {
		Swal.fire({...defaultSwalProps,
			html: "<h3 className='fw-bolder text-dark display-6'>Purge All Logs</h3><div className='text-muted fw-bold fs-3'>Are you sure you want to delete all logs?</div>"
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.startLoading();
				this.processPurgeLogs();
			}
		})
	}
	
	async processPurgeLogs() {
		this.props.purgeLogs();
		this.props.loadLogs(this.props.currentPage,this.props.currentLimit,this.props.currentSearch);
	}
	
	async exportLogs() {
		const response = await agent.Logs.exportLogs();
		if(response) {
			let aTemp = document.createElement('a');
			let windowUrl = window.URL || window.webkitURL;
			let blobSrc = windowUrl.createObjectURL(response.body);
			aTemp.href = blobSrc;
			let fileNameFull = response.header['content-disposition'];
			if (fileNameFull && fileNameFull.indexOf('attachment') !== -1) {
				let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				let matches = filenameRegex.exec(fileNameFull);
				if (matches != null && matches[1]) { 
					aTemp.download = matches[1].replace(/['"]/g, '');
				}
			}
			document.body.append(aTemp);aTemp.click();aTemp.remove();windowUrl.revokeObjectURL(blobSrc);
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Log files exported.</h3>",
				icon: "success",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-success mx-2"}
			});
		} else {
			Swal.fire({...defaultSwalProps,
				html: "<h3 className='fw-bolder text-dark display-6'>Connection failed, please try again.</h3>",
				icon: "error",confirmButtonText: "OK",showCancelButton: false,
				customClass: {confirmButton: "btn btn-lg btn-danger mx-2"}
			});
		}
	}
	
	handleModalClose = () => {
		this.setState({modalShow: false});
	}
	
	clickPagination = (pageNumber) => {
		this.props.startLoading();
		this.props.loadLogs(pageNumber,this.props.currentLimit,this.props.currentSearch);
	}
	
	clickLimit = (limitNumber) => {
		this.props.startLoading();
		this.props.loadLogs(this.props.currentPage,limitNumber,this.props.currentSearch);
	}

	render() {
		
		let [, tzName] = /.*\s(.+)/.exec((new Date()).toLocaleDateString(navigator.language, { timeZoneName: 'short' }));
		
		return (
			<>
				<div className='d-flex flex-column flex-root'>
					<div className='page d-flex flex-row flex-column-fluid'>
						<AsideDefault />
						<div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
							<HeaderWrapper title="Action Logs" currentUser={this.props.currentUser} />
							<Toolbar />
							<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
								<Content>
									<div className="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
										<div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
											<div className="d-flex align-items-center flex-wrap mr-2">
												<h5 className="text-dark font-weight-bold mt-2 mb-2">Search:</h5>
												<div className="d-flex align-items-center">
													<div className="ml-3">
														<div className="input-group input-group-md" style={{minWidth: '280px'}}>
															<input type="text" value={this.state.searchInputValue} className="form-control" placeholder="Enter email or action..." onChange={(e) => this.clickSearch(e.target.value)} />
															{this.props.currentSearch !== ''?
																<div className="input-group-append">
																	<a className="btn btn-danger btn-icon" onClick={() => this.resetSearch()}>
																		<i className="bi bi-x-circle-fill" />
																	</a>
																</div>
															:
																<div className="input-group-append">
																	<a className="btn btn-primary btn-icon" onClick={(e) => this.clickSearch(this.state.searchInputValue)}>
																		<i className="bi bi-search" />
																	</a>
																</div>
															}
														</div>
													</div>
												</div>
												<div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
												<div className="d-flex align-items-center">
													{this.props.currentSearch !== ''?
														<span className="text-dark-50 font-weight-bold" id="kt_subheader_total">{this.props.pagination.total} results for "{this.props.currentSearch}"...</span>
													: null }
												</div>
											</div>
											<div className="d-flex align-items-center">
												<a className="btn btn-bg-white btn-text-muted btn-active-info btn-sm px-4 mx-2"><i className="bi bi-calendar mr-2" />Timestamp After</a>
												<a className="btn btn-bg-white btn-text-muted btn-active-info btn-sm px-4 mx-2 filter">
													<i className="bi bi-calendar mr-2" />Timestamp Before
													<div className={clsx('card filterDrop text-start p-4 w-lg-225px',
														{show: false}
													)}>
														<a className="py-2 d-block" href="#">Admin</a>
														<a className="py-2 d-block" href="#">User</a>
													</div>
												</a>
											</div>
											{this.props.currentSearch !== '' || this.props.pagination.per_page !== 20 || this.props.pagination.current_page !== 1 ?
											<div className="d-flex align-items-center">
												<a className="btn btn-white btn-sm px-4 font-size-base ml-2" onClick={() => this.resetSearch()}><i className="bi bi-x-circle-fill"></i> Reset View</a>
											</div>
											: null }
										</div>
									</div>
									<div className="d-flex flex-column">
										<div className="card card-custom">
											<div className="card-header flex-wrap border-0 pt-6 pb-0">
												<div className="card-title">
													<h3 className="card-label">Logs
													<span className="d-block text-muted pt-2 font-size-sm">View application logs.</span></h3>
												</div>
												<div className="card-toolbar">
													<a onClick={() => this.exportLogs()} className="btn btn-primary mr-2"><i className="bi bi-filetype-csv" /> Export Logs to CSV</a>
													<a onClick={() => this.purgeLogs()} className="btn btn-danger"><i className="bi bi-trash-fill" /> Clear All Logs</a>
												</div>
											</div>
											<div className="card-body">
												<div className='table-responsive'>
													<table id='kt_table_users' className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
														<thead>
															<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
															{this.props.headers.map((column,index) => {
																if(column.sort) {
																	return (
																		<th key={index} onClick={() => this.sortHeading()}>
																			<OverlayTrigger placement='right' delay={{show: 200, hide: 400}} overlay={<Tooltip>Sort by {column.label}</Tooltip>}>
																				<span className="d-flex text-info" style={{cursor:'pointer'}}>{column.label}<i className={clsx('bi text-info ml-2',{['bi-sort-down']:(column.active && column.dir==='asc'),['bi-sort-up']:(column.active && column.dir==='desc')})} /></span>
																			</OverlayTrigger>
																		</th>
																	);
																} else {
																	return (
																		<th key={index}>
																			<span>{column.label}</span>
																		</th>
																	);
																}
															})}
															</tr>
														</thead>
														<tbody className='text-gray-600 fw-bold'>
															{this.props.logs.length > 0 ? (
																this.props.logs.map((row) => {
																	let email = (row.email)?row.email:'<deleted>';
																	let created_at = moment.utc(row.created_at).local().format('MMM. Do YYYY, h:mm A');
																	let actionText = row.method;
																	if (actionText && actionText.indexOf('::') !== -1) {
																		let actionTextSplit = actionText.split('::');
																		if (actionTextSplit != null && actionTextSplit[1]) { 
																			row.method = actionTextSplit[1];
																		}
																	}
																	return (
																		<tr key={row.id}>
																			<td>{created_at} {tzName}</td>
																			<td>{email} (ID:{row.user_id})</td>
																			<td>{row.type}</td>
																			<td>{row.method}</td>
																			<td>{row.log_body}</td>
																		</tr>
																	);
																})
															) : (
																<tr>
																	<td colSpan={3}>
																		<div className='d-flex text-center w-100 align-content-center justify-content-center'>No results found.</div>
																	</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
												<ListPagination pagination={this.props.pagination} results={this.props.logs} clickPagination={this.clickPagination} clickLimit={this.clickLimit} isLoading={this.props.isLoading} />
												{this.props.isLoading && <ListLoading />}
											</div>
										</div>
									</div>
								</Content>
							</div>
							<Footer />
						</div>
					</div>
				</div>
				<ModalGeneral show={this.state.modalShow} handleSubmit={this.handleSubmit} handleClose={this.handleModalClose} thisData={this.props.logs} action={this.props.action} isLoading={this.props.isLoading} />
				<ScrollTop />
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogs);
