import React from "react";
import { connect } from "react-redux";
import agent from "../agent";

import { Content } from "../widgets/components/Content";
import { Footer } from "../widgets/components/Footer";
import { HeaderWrapper } from "../widgets/components/header/HeaderWrapper";
import { ScrollTop } from "../widgets/components/ScrollTop";
import { Countdownclock } from "./Countdownclock";
import ICSDownload from "./ICSDownload";
// import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../partials'
import moment from "moment";
import Swal from "sweetalert2";
import { ModalContact } from "../widgets/components/ModalContact";
import { ModalSpeakersFrontend } from "../widgets/components/ModalSpeakersFrontend";

import {
  LOAD_AGENDAS,
  LOAD_MEETING,
  LOAD_PDFS,
  LOAD_SPEAKERS,
  START_LOADING,
  STOP_LOADING,
} from "../constants/actionTypes";

const mapStateToProps = (state) => ({
  ...state.common,
  currentUser: state.common.currentUser,
  meeting: state.meeting.meeting,
  agendas: state.agendas.agendas,
  speakers: state.speakers.speakers,
  pdfs: state.pdfs.pdfs,
});

const mapDispatchToProps = (dispatch) => ({
  loadMeeting: (id) =>
    dispatch({ type: LOAD_MEETING, payload: agent.Meetings.loadMeeting(id) }),
  loadAgendas: (page, limit, search) =>
    dispatch({
      type: LOAD_AGENDAS,
      payload: agent.Agendas.loadAgendas(
        page,
        limit,
        search,
        "start_at",
        "asc"
      ),
    }),
  loadSpeakers: (page, limit, search) =>
    dispatch({
      type: LOAD_SPEAKERS,
      payload: agent.Speakers.loadSpeakers(page, limit, search),
    }),
  loadPdfs: (page, limit, search) =>
    dispatch({
      type: LOAD_PDFS,
      payload: agent.Pdfs.loadPdfs(page, limit, search),
    }),
  startLoading: () => dispatch({ type: START_LOADING }),
  stopLoading: () => dispatch({ type: STOP_LOADING }),
});

const defaultSwalProps = {
  icon: "info",
  buttonsStyling: false,
  padding: "0 0 2.0em",
  confirmButtonText: "Yes, Delete",
  customClass: {
    confirmButton: "btn btn-lg btn-info mx-2",
    cancelButton:
      "btn btn-lg btn-light mx-2 btn-active-icon-dark btn-active-text-dark",
  },
  showCancelButton: true,
  cancelButtonText: "Wait! Cancel",
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 400,
      modalShow: false,
      modalShowSpeakers: false,
    };
  }

  componentDidMount() {
    this.props.loadMeeting(1);
    this.props.loadAgendas(1, 100, "");
    this.props.loadSpeakers(1, 100, "");
    this.props.loadPdfs(1, 100, "");
    this.handleSubmit = this.handleSubmit.bind(this);
    //window.addEventListener('resize', this.updateWindowDimensions);
    //console.log('componentDidMount',this.props);
    //$("html, body").animate({ scrollTop: 0 }, "slow");
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {}

  handleCompleteTimer() {}

  createMarkup(html) {
    return { __html: html };
  }

  contactUs = () => {
    this.setState({ modalShow: true });
  };

  speakerProfiles = () => {
    this.setState({ modalShowSpeakers: true });
  };

  async handleSubmit(submitData) {
    this.props.startLoading();
    let that = this;
    let response = null;
    response = await agent.Frontend.sendSupportForm(submitData);
    if (response?.success === true) {
      Swal.fire({
        ...defaultSwalProps,
        html: "<h3 className='fw-bolder text-dark display-6'>Your support request has been sent, and we will get back to you shortly.</h3>",
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
        customClass: { confirmButton: "btn btn-lg btn-success mx-2" },
      });
      that.setState({ modalShow: false });
      that.props.stopLoading();
    } else {
      let message = response?.message
        ? response.message
        : "Connection failed, please try again.";
      Swal.fire({
        ...defaultSwalProps,
        html:
          "<h3 className='fw-bolder text-dark display-6'>" + message + "</h3>",
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
        customClass: { confirmButton: "btn btn-lg btn-danger mx-2" },
      });
      that.props.stopLoading();
    }
  }

  async handleUserAction(submitData) {
    let response = null;
    response = await agent.Frontend.sendUserAction(submitData);
    if (response?.success === true) {
    }
  }

  handleModalClose = () => {
    this.setState({ modalShow: false });
  };

  handleSpeakerModalClose = () => {
    this.setState({ modalShowSpeakers: false });
  };

  render() {
    const timeNow = new Date().getTime();

    // const start_at = moment.utc(this.props.meeting.start_stmp).format();//console.log(start_at, "- now in UTC");
    // const local_start_at = moment.utc(start_at).local().toDate();//console.log(local_start_at, "- UTC now to local");

    const jsTime = this.props.meeting.start_stmp
      ? this.props.meeting.start_stmp * 1000
      : timeNow;

    const local_date = moment
      .utc(this.props.meeting.start_at)
      .local()
      .format("MMM. Do YYYY");
    const local_time = moment
      .utc(this.props.meeting.start_at)
      .local()
      .format("h:mm A");
    const local_end_time = moment
      .utc(this.props.meeting.end_at)
      .local()
      .format("h:mm A");

    let meeting_url = this.props.meeting.meeting_video_source
      ? this.props.meeting.meeting_video_source
      : "https://player.vimeo.com/video/627438345?h=c7461cdefd&amp;title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;autopause=0&amp;quality=720p&amp;app_id=122963";
    meeting_url += this.props.meeting.meeting_video_password
      ? "&password=" + this.props.meeting.meeting_video_password
      : "";

    let [, tzName] = /.*\s(.+)/.exec(
      new Date().toLocaleDateString(navigator.language, {
        timeZoneName: "short",
      })
    );

    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <div
              className="wrapper px-0 d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <HeaderWrapper
                title="Dashboard"
                pageType="user"
                currentUser={this.props.currentUser}
              />
              <div
                id="kt_content"
                className="content d-flex flex-column flex-column-fluid home"
              >
                <Content>
                  <>
                    {(this.props.meeting.active === 1 && timeNow > jsTime) ||
                    this.props.currentUser.role === "admin" ? (
                      <div id="meeting" className="text-center">
                        <div className="container py-5">
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <h1 className="font-weight-boldest display-6 my-8">
                                {this.props.meeting.title}
                              </h1>
                              <p className="font-weight-bold">
                                {local_date}
                                <br />
                                {local_time} {tzName}
                              </p>
                              <div className="row py-4">
                                <div className="col-md-6 text-center py-4">
                                  {this.props.meeting && (
                                    <p>
                                      <a
                                        href={`/video/${this.props.meeting.id}`}
                                        className="btn btn-lg btn-primary"
                                        target="_blank"
                                        onClick={() =>
                                          this.handleUserAction({
                                            type: "click",
                                            action:
                                              "Meeting Video Link: " +
                                              this.props.meeting.id,
                                          })
                                        }
                                      >
                                        View the Live Stream
                                      </a>
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-6 text-center py-4">
                                  {this.props.pdfs?.length > 0
                                    ? this.props.pdfs.map((pdf) => {
                                        return (
                                          <p key={pdf.id}>
                                            <a
                                              href={`/pdf/${pdf.id}`}
                                              className="btn btn-lg btn-primary"
                                              target="_blank"
                                              onClick={() =>
                                                this.handleUserAction({
                                                  type: "click",
                                                  action:
                                                    "PDF Link: " + pdf.title,
                                                })
                                              }
                                            >
                                              {pdf.title}
                                            </a>
                                          </p>
                                        );
                                      })
                                    : null}
                                </div>
                              </div>
                              {this.props.currentUser.role === "admin" ? (
                                <div className="mt-4 font-weight-bold">
                                  <p>(Testing for admins only)</p>
                                  <ICSDownload />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : this.props.meeting.active === 0 ? (
                      <div id="countdown" className="text-center">
                        <div className="container py-5">
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <h1 className="font-weight-boldest display-6 my-8">
                                {this.props.meeting.title}
                              </h1>
                              <div className="alert alert-info">
                                Meeting is closed.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : timeNow < jsTime ? (
                      <div id="countdown" className="text-center">
                        <div className="container py-5">
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <h1 className="font-weight-boldest display-6 my-8">
                                {this.props.meeting.title}
                              </h1>
                              <p className="font-weight-bold">
                                {local_date}
                                <br />
                                {local_time} {tzName}
                              </p>
                              <Countdownclock
                                countdownDate={jsTime}
                                handleCompleteTimer={this.handleCompleteTimer}
                              />
                              <div className="text-center py-4">
                                {this.props.pdfs?.length > 0
                                  ? this.props.pdfs.map((pdf) => {
                                      return (
                                        <p key={pdf.id}>
                                          <a
                                            href={`/pdf/${pdf.id}`}
                                            className="btn btn-primary"
                                            target="_blank"
                                            onClick={() =>
                                              this.handleUserAction({
                                                type: "click",
                                                action:
                                                  "PDF Link: " + pdf.title,
                                              })
                                            }
                                          >
                                            {pdf.title}
                                          </a>
                                        </p>
                                      );
                                    })
                                  : null}
                              </div>
                              <div className="mt-4 font-weight-bold">
                                <ICSDownload />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.meeting.active === 1 ? (
                      <div id="agenda">
                        <div className="container py-5">
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <h2 className="display-6 pb-5 text-center">
                                Agenda
                              </h2>
                              {this.props.speakers &&
                              this.props.speakers.length > 0 ? (
                                <p className="font-weight-bold text-center">
                                  <a
                                    className="btn btn-lg btn-primary"
                                    onClick={() => this.speakerProfiles()}
                                  >
                                    Speaker Profiles
                                  </a>
                                </p>
                              ) : null}
                              <table className="table w-100">
                                <tbody>
                                  <tr>
                                    <td>
                                      {local_time} - {local_end_time} {tzName}
                                    </td>
                                    <td>
                                      <strong>
                                        Revelstoke Capital Partners’ Annual
                                        Meeting
                                      </strong>
                                      <p>
                                        All times (adjusted to your local time
                                        zone) and order of events are subject to
                                        change.
                                      </p>
                                    </td>
                                    <td>
                                      <strong>.</strong>
                                    </td>
                                  </tr>
                                  {this.props.agendas?.length > 0
                                    ? this.props.agendas.map((row) => {
                                        let start = moment
                                          .utc(row.start_at)
                                          .local()
                                          .format("h:mm A");
                                        let end = moment
                                          .utc(row.end_at)
                                          .local()
                                          .format("h:mm A");
                                        return (
                                          <tr key={row.id}>
                                            <td
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {start} - {end} {tzName}
                                            </td>
                                            <td>
                                              <strong>{row.title}</strong>
                                              <div
                                                dangerouslySetInnerHTML={this.createMarkup(
                                                  row.desciption
                                                )}
                                              ></div>
                                            </td>
                                            <td>
                                              <strong>{row.speaker}</strong>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.meeting.active === 1 ? (
                      <div id="help">
                        <div className="container py-5">
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <h2 className="display-6 pb-5 text-center">
                                Help
                              </h2>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8 offset-md-2">
                              <p className="font-weight-bold text-center">
                                The Support button below reaches all available
                                personnel and provides the fastest response.
                              </p>
                              <p className="font-weight-bold text-center">
                                <a
                                  className="btn btn-lg btn-primary"
                                  onClick={() => this.contactUs()}
                                >
                                  Support
                                </a>
                              </p>
                              <p>Contacts:</p>
                              <p>
                                <span className="font-weight-bold">
                                  Julie Allen (Operations)
                                </span>
                                <br />
                                <a href="mailto:jallen@revelstokecapital.com">
                                  jallen@revelstokecapital.com
                                </a>
                                <br />
                                Text or call:{" "}
                                <a href="tel:+13033582233">(303) 358-2233</a>
                              </p>
                              <p>
                                <span className="font-weight-bold">
                                  IT Support Team
                                </span>
                                <br />
                                <a href="mailto:rcplivesupport@kisecurity.com">
                                  rcplivesupport@kisecurity.com
                                </a>
                                <br />
                                Text or call:{" "}
                                <a href="tel:+17206611644">(720) 661-1644</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                </Content>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        {/* begin:: Drawers 
				<ActivityDrawer />
				<RightToolbar />
				<DrawerMessenger />
				end:: Drawers */}

        {/* begin:: Modals
				<Main />
				<InviteUsers />
				<UpgradePlan />
				end:: Modals */}
        <ModalContact
          show={this.state.modalShow}
          handleSubmit={this.handleSubmit}
          handleClose={this.handleModalClose}
          isLoading={this.props.isLoading}
        />
        <ModalSpeakersFrontend
          show={this.state.modalShowSpeakers}
          speakers={this.props.speakers}
          handleClose={this.handleSpeakerModalClose}
        />
        <ScrollTop />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
