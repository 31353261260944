import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

type Props = {
  title: string
	pageType: string
	currentUser: any
}

const HeaderWrapper: React.FC<Props> = ({title, pageType, currentUser}) => {
  const {config, classes, attributes} = useLayout()
  const {header, pageTitle} = config

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'bg-white align-items-stretch')}
			style={(pageType === 'user')?{left:'0px'}:{}}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'container-fluid d-flex align-items-stretch justify-content-between'
        )}
      >
				
				{title &&
					<h3 className="d-none text-dark d-lg-flex align-items-center mr-10 mb-0">{title}</h3>
				}
				
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div className='btn btn-icon btn-active-color-primary w-40px h-40px' id='kt_aside_toggle'>
            <KTSVG path='/images/utility/abs015.svg' className='svg-icon-1' />
          </div>
        </div>
				
        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
          <Link to='/dashboard' className='d-lg-none'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/images/splash-logo.svg')}
              className='h-30px'
            />
          </Link>
        </div>

        {pageTitle?.display && (
          <div className='d-flex align-items-center' id='kt_header_wrapper'>
            <DefaultTitle />
          </div>
        )}

        <div className={clsx('d-flex align-items-stretch',`justify-content-${header.menu ? 'between' : 'end'}`,'flex-lg-grow-1')}>
          {header.menu && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}
          <Topbar currentUser={currentUser} />
        </div>
      </div>
    </div>
  )
}

export {HeaderWrapper}